.l-metrics-form {
  position: absolute;
  top: 10px;
  right: 57px;
  z-index: 2;
  width: 300px;

  &__form,
  &__legend {
    background-color: $g-gray-color-6;
  }

  &__form {
    padding: 16px;
    border-radius: 0 0 12px 12px;
  }

  &__legend {
    @include bp-sm {
      margin-top: 16px;
      padding: 16px;
      border-radius: 12px;
    }
  }

  &__label {
    margin: 15px 0 0;
    padding: 0;
    color: #4e4b66;
    font-size: 12px;
    font-weight: bold;
  }

  &__form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    color: #4e4b66;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 15px;
  }

  &__select {
    width: 100%;
    font-size: 12px;
  }

  &__radio-group {
    width: 100%;
  }

  &__button {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    height: 42px;
    background-color: $g-gray-color-6;
    padding: 0 20px;
    line-height: 1;
    font-weight: bold;
    color: $g-brand-color-1;
    border: 1px solid $g-brand-color-1;

    &:first-child {
      flex: 1 1;
      margin-right: 10px;
    }

    &--cancel {
      background-color: #a799ff;
      color: #000;
      font-weight: bold;
      border: 1px solid #a799ff;
    }

    &--full {
      width: 100%;
      margin-top: 15px;
    }

    &--submit {
      background-color: $g-brand-color-1;
      color: $g-white;
      width: 100%;
      margin-top: 15px;
      font-weight: normal;
    }
  }

  &__submit {
    margin-top: 15px;
    margin-left: auto;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    height: 42px;
    background-color: $g-brand-color-1;
    color: $g-white;
    padding: 0 20px;
    line-height: 1;

    &--mute {
      background-color: white;
      color: #171725;
      border-color: #979797;
    }
  }

  &__enable-select-tool {
    display: block;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    border: 1px solid #e7e7e7;
    color: $g-brand-color-1;
    background-color: white;
    font-weight: 500;
    // transition: $transition;

    &:hover {
      color: $g-white;
      background-color: $g-brand-color-1;
    }
  }

  &__menu-metric-range-colors {
    display: flex;
    margin: 5px 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      bottom: -5px;
      left: 50%;
      height: 10px;
      width: 1px;
      margin-left: -1;
      background-color: #ccc;
      position: absolute;
    }

    li {
      flex: 1 1;
      margin: 0;
      padding: 0;

      > span {
        height: 15px;
        display: block;
        width: 100%;
      }

      .middle {
        border: solid #e9e9e9;
        border-width: 1px 0;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
        overflow: hidden;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        overflow: hidden;
      }
    }
  }

  &__heading {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid #d9dbe955;
    background-color: $g-gray-color-6;
    border-radius: 12px 12px 0 0;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  &__menu-metric-range-wrapper {
    display: flex;
    justify-content: space-between;
    color: #000;
  }

  &__menu-metric-range-wrapper & {
    &__text {
      margin-top: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      width: 12px;
      stroke: $g-brand-color-1;
      margin-left: 10px;
    }
  }

  &__action-button {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $g-brand-color-1;
    font-weight: bold;
  }

  &__hint {
    margin-top: 16px;
    background-color: $g-gray-color-6;
    border-radius: 12px;
    padding: 16px;
    font-size: 15px;
    font-weight: normal;
    font-size: 14px;

    p {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    button:first-child {
      flex: 1 1 100%;
      margin-right: 10px;
    }
  }

  &--mobile {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    background-color: $g-white;
    border-radius: 12px 12px 0 0;
    padding: 16px;
    width: 100%;
  }

  /* Overrides */

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 34px;
  }

  .ant-radio-button-wrapper {
    width: auto;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    border-color: transparent;
  }

  .ant-radio-group {
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}
