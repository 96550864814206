.l-channel-options {
  padding: 10px 20px 20px;

  .error {
    color: #f00;
  }

  &__detail {
    margin-bottom: 15px;

    > span {
      font-size: 13px;
      color: #afafaf;
      display: block;
      margin-bottom: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > p {
      > span + span {
        margin-left: 10px;
      }
    }

    > div {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .ant-upload {
      width: 100%;
    }

    .error {
      margin-top: 10px;
      text-align: center;
    }

    .img-cont {
      width: 100%;
      height: 234px;
      position: relative;
      background-color: #e5e5e5;
      border-radius: 8px;

      > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 12px;
      }

      .img-cont__overlay {
        margin-bottom: 0;
        border-radius: 4px;
        background-color: white;
        height: 35px;
        width: 35px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $g-brand-color-1;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      }
    }
  }

  &__switch {
    list-style: none;
    padding: 0;
  }

  &__switch-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &:first-child {
      margin-top: 24px;
    }
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 5px 8px;
    border: 2px solid $g-brand-color-1;
    color: $g-brand-color-1;
    height: 42px;
    margin-top: 40px;
    border-radius: 22px;
  }

  &__confirm {
    text-align: center;
    margin-top: 40px;

    p {
      color: #ee6d00;
    }

    &.proceed {
      p {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    button {
      height: 35px;
      width: 35px;
      margin: 10px 20px 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    [type='submit'] {
      svg {
        path {
          stroke: $g-brand-color-1;
        }
      }
    }
  }

  .edit-details {
    display: flex;
    align-items: center;
    color: $g-brand-color-1;
    font-size: 13px;

    svg {
      margin-left: 5px;
    }
  }

  .ant-form-item {
    margin-bottom: 15px;
  }
}
