//////////////////////////////////////////////////
//                                              //
//  Normalize and Reset                         //
//  http://www.alqamabinsadiq.com                 //
//                                              //
//////////////////////////////////////////////////

/**
 * Let's get all browsers rendering all elements more consistently and in line
 * with modern standards, and let's nuke certain user-agent styles so that
 * everything is on an even playing field.
 *
 * We can also be a bit opinionated about CM specific style treatments, marked
 * with "N.B. Dreamers of Day specific".
 *
 * N.B. we should be keeping an eye on the normalize.css repo especially the
 * Issues: https://github.com/necolas/normalize.css/issues/.
 *
 * @credit
 * https://necolas.github.io/normalize.css/
 *
 * ----------------------------------------------------------------------------
 *
 * CONTENTS:
 *
 * 1. Root element and global resets
 * 2. Block-level elements
 * 3. Inline elements
 * 4. Embedded elements
 * 5. Form elements
 * 6. Interactions
 */

/* 1. Root element and global resets
   ========================================================================= */

/**
 * 1. Reset the `box-sizing` property to the more friendly `border-box` value,
 *    N.B. Dreamers of Day specific.
 * 2. Prevent adjustments of font size after orientation changes in IE on
 *    Windows Phone and in iOS.
 */

html {
  box-sizing: border-box; // [1]
  /* stylelint-disable property-no-vendor-prefix */
  -ms-text-size-adjust: 100%; // [2]
  -webkit-text-size-adjust: 100%; // [2]
  /* stylelint-enable */
}

// [1]
/* stylelint-disable selector-no-universal */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* stylelint-enable */

/**
 * Remove all margins, paddings, and borders.
 *
 * N.B. do not include the following elements:
 * - `<input>`
 * - `<button>`
 * - `<textarea>`
 *
 * The complete list of HTML5 elements:
 * https://developer.mozilla.org/en/docs/Web/Guide/HTML/HTML5/
 * HTML5_element_list
 *
 * N.B. Dreamers of Day specific.
 */

a,
abbr,
address,
article,
aside,
b,
blockquote,
body,
caption,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
// dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
// li,
main,
menu,
nav,
object,
// ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
u,
// ul,
var {
  border: 0;
  margin: 0;
  padding: 0;
}

/* 2. Block-level elements
   ========================================================================= */

/**
 * Add the correct display in IE 9-.
 *
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */

article,
aside,
details, // [1]
figcaption,
figure,
footer,
header,
nav,
main, // [2]
menu,
section {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/**
 * 1. Add the correct display in IE.
 * 2. Add the correct display in IE 10-.
 */

template, // [1]
[hidden] {
  // [2]
  display: none;
}

/**
 * Reset lists, this is in addition to removing `margin` and `padding` in
 * "Root element and global resets".
 *
 * N.B. Dreamers of Day specific.
 */

ul,
ol {
  list-style-type: none;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; // [1]
  height: 0; // [1]
  overflow: visible; // [2]
}

/* 3. Inline elements
   ========================================================================= */

/**
 * Add the correct display in IE 9-.
 */

audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
  background-color: transparent; // [1]
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-decoration-skip: objects; // [2]
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers.
 */

a:active,
a:hover {
  outline-width: 0;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; // [1]
  text-decoration: underline; // [2]
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  text-decoration: underline dotted; // [2]
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

/* stylelint-disable-next-line no-duplicate-selectors */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
  font-style: italic;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  /* stylelint-disable-next-line declaration-property-unit-whitelist */
  font-size: 100%;
}

/**
 * Prevent `<sub>` and `<sup>` affecting line height in all browsers.
 */

sub,
sup {
  /* stylelint-disable-next-line declaration-property-unit-whitelist */
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace; // [1]
  font-size: 1em; // [2]
}

/* 4. Embedded elements
   ========================================================================= */

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* 5. Form elements
   ========================================================================= */

/**
 * 1. Change font properties to `inherit` in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font: inherit; // [1]
  margin: 0; // [2]
}

/**
 * Show the overflow in IE.
 *
 * 1. Show the overflow in Edge.
 */

button,
input {
  // [1]
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 *
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  // [1]
  text-transform: none;
}

/**
 * Reset all styles so we have a blank canvas.
 *
 * N.B. Dreamers of Day specific.
 */

button {
  background: transparent;
  border: 0;
  padding: 0;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `<audio>` and `<video>`
 *    controls in Android 4.
 * 2. Improve usability and consistency of cursor style between image-type
 *    `<input>` and others (N.B. Dreamers of Day specific).
 * 3. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type='button'], // [1]
[type='reset'],
[type='submit'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: button; // [3]
  cursor: pointer; // [2]
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: none !important;
}

/**
 * Indicate that `<label>` will shift focus to the associated `<input>`
 * element.
 *
 * N.B. Dreamers of Day specific.
 */

label {
  cursor: pointer;
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Disable `<textarea>`s from being resized horizontally
 *    (N.B. Dreamers of Day specific).
 */

textarea {
  overflow: auto; // [1]
  resize: vertical; // [2]
}

/**
 * Chrome and Firefox set a `min-width: min-content;` on `<fieldset>`s, so we
 * reset that to ensure it behaves more like a standard block element, see:
 * http://stackoverflow.com/questions/17408815/
 * fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content.
 *
 * N.B. Dreamers of Day specific.
 */

fieldset {
  min-width: 0;
}

// Firefox only
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the colour inheritance from `<fieldset>` elements in IE.
 */

legend {
  color: inherit; // [2]
  display: table; // [1]
  max-width: 100%; // [1]
  white-space: normal; // [1]
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Apply the `not-allowed` cursor for disabled elements (`<button>`, `<input>`,
 * `<textarea>`, `<optgroup>`, `<option>` and `<fieldset>`) and disable pointer
 * events.
 *
 * N.B. Dreamers of Day specific.
 */

:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; // [1]
  padding: 0; // [2]
}

/**
 * Remove the increment and decrement buttons in Webkit and Firefox.
 *
 * N.B. Dreamers of Day specific.
 */

// Firefox
[type='number'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}

// Webkit
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  margin: 0;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: textfield; // [1]
  outline-offset: -2px; // [2]
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

/* stylelint-disable-next-line no-descending-specificity */
::-webkit-file-upload-button {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: button; // [1]
  font: inherit; // [2]
}

/**
 * Remove the clear field button (an "X" icon) and the reveal password button
 * (an "eye" icon) that is generated by IE 10+ for textual inputs. See:
 * http://stackoverflow.com/a/21869433/1116204.
 *
 * N.B. Dreamers of Day specific.
 */

/* stylelint-disable no-descending-specificity */
::-ms-clear,
::-ms-reveal {
  display: none;
}

/* stylelint-enable */

/**
 * Unstyle the caret on `<select>`s in IE 10+.
 *
 * N.B. Dreamers of Day specific.
 */

/* stylelint-disable-next-line no-descending-specificity */
::-ms-expand {
  background-color: transparent;
  border: 0;
}

/**
 * Rules for iOS devices.
 *
 * We isolate these rules behind an `.target-ios` class that is appended to the
 * `<html>` element via user-agent sniffing, for example:
 *
    <html class="target-ios">
 *
 * N.B. this functionality is not part of the Dreamers of Day library, you will need to
 * add it yourself to the project Dreamers of Day applies too.
 *
 * N.B. Dreamers of Day specific.
 */

.target-ios {
  /**
     * Fixes "clickability" issue (and more generally, the firing of events
     * such as focus as well) for traditionally non-focusable elements with
     * `[role="button"]`, see:
     * https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
     */

  [role='button'] {
    cursor: pointer;
  }

  /**
     * Remove the top inner shadow from iOS inputs. See:
     * https://davidwalsh.name/input-shadows-ipad
     */

  /**
     * Remove rounded corners that iOS applies to all `<input>` buttons.
     */

  [type='submit'],
  [type='button'],
  [type='image'],
  [type='reset'] {
    border-radius: 0;
  }
}

/* 6. Interactions
   ========================================================================= */

/**
 * Suppress the focus outline on links that cannot be accessed via a keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 *
 * N.B. it is okay to use `!important` here as we're doing it pre-emptively
 * i.e. you know you will always want the rule it's applied too to take
 * precedence.
 *
 * N.B. Dreamers of Day specific.
 */

[tabindex='-1']:focus {
  outline: none !important;
}

/**
 * Don't use outline on Mozilla browsers, if it's not needed.
 */

:focus:not(:-moz-focusring) {
  outline: none !important;
}

/**
 * Avoid 300ms click delay on touch devices that support the `touch-action` CSS
 * property.
 *
 * In particular, unlike most other browsers, IE 11+Edge on Windows 10 on touch
 * devices and IE Mobile 10-11 DON'T remove the click delay when
 * `<meta name="viewport" content="width=device-width">` is present.
 * However, they DO support removing the click delay via
 * `touch-action: manipulation`.
 *
 * See:
 * - http://caniuse.com/#feat=css-touch-action
 * - http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
 * - https://adactio.com/journal/10019
 * - https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
 */

/* stylelint-disable no-descending-specificity */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex]:not([tabindex='-1']) {
  touch-action: manipulation;
}

/* stylelint-enable */

/**
 * Remove `:focus` styles for `coarse` input mechanisms, and on the `:hover`
 * pseudo class.
 */

@media (pointer: coarse) and (hover: none) {
  :focus {
    outline: none;
  }
}
