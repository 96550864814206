.l-map-style-picker {
  list-style: none;
  padding: 0;
  margin: 0;

  &__option {
    height: 42px;
    width: 42px;
    margin: 5px 0 0;
    border: 2px solid $g-offwhite;
    border-radius: 50%;
    overflow: hidden;
    background-color: $g-offwhite;

    &--active {
      border: 3px solid $g-brand-color-10;
      position: relative;

      &:after {
        background: $g-brand-color-1;
        mix-blend-mode: multiply;
        z-index: 1;
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    button {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $g-offwhite;
      text-align: center;
      box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    }
  }
}
