.l-chat-browse-channels {
  width: calc(100% - 300px);
  @include bpm-sm {
    width: 100%;
    // height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background: $g-tgray-color-7;
    width: 100%;
    padding: 0px 32px;
    border-top: 1px solid $g-light-color-1;
  }
  &__header-title {
    font-weight: 600;
    font-size: 18px;
  }
  &__header-btn {
    border-radius: 22px;
    color: $g-brand-color-1;
    outline: $g-brand-color-1;
    border-color: $g-brand-color-1;
    transition: $g-trans-base;
    &:hover {
      border-color: lighten($color: $g-brand-color-1, $amount: 8);
      color: lighten($color: $g-brand-color-1, $amount: 8);
    }
  }

  &__header-btn-round {
    border-radius: 50%;
    color: $g-brand-color-1;
    outline: $g-brand-color-1;
    border-color: #fff !important;
    transition: $g-trans-base;
    background-color: #eff0f6 !important;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 8px;

    &:hover {
      border-color: lighten($color: $g-brand-color-1, $amount: 8);
      color: lighten($color: $g-brand-color-1, $amount: 8);
    }
  }
  &__search-field {
    border: none;
    background: $g-light-color-1;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    input {
      height: 50px;
      background: $g-light-color-1;
      margin-left: 5px;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
  &__channels-list-wrapper {
    background-color: $g-brand-color-11;
    height: 100%;
    width: 100%;
    padding: 30px;
  }
  &__channels-list-config {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $g-dark-color-2;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  &__channels-list-config-sort-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
    color: $g-dark-color-2;

    svg {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }
  }
  &__channels-list {
    overflow-y: scroll;
  }
  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $g-tgray-color-7;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 16px 26px;
    transition: border 0.3s ease-in-out;
    &-actions {
      visibility: hidden;
      opacity: 0;
      transition: $g-trans-base;
    }
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $g-brand-color-1;
      .l-chat-browse-channels__list-item-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__list-item-title {
    font-weight: 600;
    font-size: 15px;
    color: $g-gray-color-2;

    &--member {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    @include bpm-sm {
      max-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
    }
  }
  &__list-item-details {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  &__list-item-join-btn {
    border-radius: 22px;
    width: 70px;
    min-width: 70px;
  }
}
