@import './chat_message/chat_message.style';
@import './chat_thread/chat_thread.style';

.l-chat-window {
  overflow: hidden;
  width: 100%;
  display: flex;
  @include bpm-sm {
    width: 100%;
    height: 100%;
  }

  &__chat-section {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $g-brand-color-11;
    overflow: hidden;
    transition: $g-trans-base;

    &--thread-visible {
      @include bp-sm {
        width: calc(100% - 300px);
      }
    }
  }

  &__header {
    @extend %chat-window-header;
  }

  &__header-left-side {
    display: flex;
    align-items: center;
  }

  &__header-right-side {
    display: flex;

    > button {
      margin-right: 10px;
    }

    .ant-avatar-group {
      cursor: pointer;
    }
  }

  &__header-conversation-title {
    color: $g-gray-color-2;
    font-weight: 600;
    font-size: 18px;
  }

  &__header-conversation-title-text {
    cursor: pointer;
  }

  &__header-menu-icon {
    margin-right: 18px;
    line-height: 1;
    cursor: pointer;
  }

  &__header-back-icon {
    margin-right: 24px;
    line-height: 1;
    cursor: pointer;
  }

  &__msg-container {
    @extend %chat-window-msg-container;
  }

  &__msg-editor-container {
    @extend %chat-window-msg-editor-container;
  }

  &__thread-parent-message {
    margin-bottom: 10px;

    .chat-thread-message {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  &__thread-reply-count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 20px;

    span {
      white-space: nowrap;
      font-size: 13px;
      color: $g-gray-color-1;
    }

    &:after {
      content: '';
      height: 1px;
      flex: 1 1 100%;
      margin-left: 6px;
      background-color: $g-grey-light;
    }
  }
}
