.l-common-form-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .l-common-form {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    padding: 20px 0;

    &__success-message {
      text-align: center;
    }

    &__button {
      width: 70% !important;
      margin: 0 auto;
      height: 45px;
    }

    &__form {
      padding: 40px;
      border-radius: 17px;
      background-color: white;
      border-radius: 24px;
      background-color: #ebecfe99;
    }

    &__textarea {
      height: 100px !important;
      padding: 8px 11px !important;
      background-color: #fff !important;
    }

    &__radio-group {
      display: flex;
    }

    &__radio-group-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__radio-group-text {
      margin-left: 4px;
    }

    &__radio-group-check {
      color: #008a00;
      font-size: 16px;
    }

    &__radio-group-close {
      color: #c86000;
      font-size: 16px;
    }

    &__details {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: 38px;
    }

    &__details-item {
      display: flex;
      margin-top: 0;

      &:first-child {
        margin-top: 0;
      }

      span:first-child {
        margin-right: 10px;
        max-width: 115px;
        flex: 1 1;
      }

      &--column {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        span:last-child {
          color: #83838f;
        }
      }
    }

    .ant-radio-button-wrapper {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 12px 0 0 12px;
    }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0 12px 12px 0;
    }
  }
}
