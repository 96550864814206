.l-search-field {
  width: 100%;

  &__button-group {
    margin-left: auto;
    display: flex;
    // padding-right: 24px;
    position: absolute;
    top: 10px;
    right: 24px;
    background: $g-white;
  }

  &__suggestions {
    background-color: $g-white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 10px 0;
    margin-top: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
  }

  &__suggestions-list {
    overflow-y: auto;
    max-height: 135px;
    padding: 0;
    margin: 0;
  }

  &__bullet {
    margin: 0 13px;

    &:before {
      content: '\2022';
    }
  }

  &__desc {
    font-size: 13px;
    opacity: 0.8;
  }

  &__suggestions-item {
    cursor: pointer;
    color: #6e7191;
    padding: 5px 32px;
    transition: $common-transition;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    strong {
      color: #14142b;
    }

    &:hover {
      background-color: #f2f4f5;
    }
  }

  &__suggestions-empty {
    color: #6e7191;
    padding: 5px 24px;
  }

  &__input {
    background-color: $g-white;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 0 12px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include bp-sm {
      padding: 15px 0;
    }
  }

  &__input-content {
    min-height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    position: relative;
  }

  &__error {
    padding-top: 15px;
    color: #e61b00;
    border-top: 1px solid $g-dark-color-3;
    width: 100%;
    font-size: 13px;
    margin-top: 15px;
  }

  &__mobile-error {
    color: #e61b00;
    width: 100%;
    font-size: 13px;
    margin-top: 15px;
    padding: 0 16px;
    text-align: center;
  }

  &__button {
    height: 38px;
    border-radius: 22px;
    background-color: $g-brand-color-1;
    color: $g-white;
    min-width: 85px;
    text-align: center;
    border: 2px solid $g-brand-color-1;
    margin: 0 0 0 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: normal;

    &--light {
      color: $g-brand-color-1;
      background-color: $g-white;
      font-weight: bold;

      span {
        margin-left: 10px;
      }
    }
  }

  &__desktop-done {
    position: absolute;
    top: 9px;
    right: 24px;
    z-index: 3;
    background-color: $g-dark-color-1;
    border-color: $g-dark-color-1;
    margin-left: 0;
  }

  &__selected {
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    display: flex;
    flex: 1 1;
    max-height: 138px;
    overflow-y: auto;

    @include bp-sm {
      padding: 0 24px;
    }

    &--desktop {
      padding: 0 200px 0 24px;
    }

    input {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0 8px;
      background: 0 0;
      border: none;
      outline: none;
      width: 100%;
      max-width: 100%;
      height: 40px;
      text-overflow: ellipsis;
      width: 100%;

      &[disabled] {
        opacity: 1;
        color: $g-dark-color-1;
      }

      @include bp-sm {
        min-width: 250px;
      }

      @include bp-md {
        min-width: 350px;
      }
    }
  }

  &__overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
  }

  &__overflow-item-suggestion {
    background-color: #f2f4f5;
    border-radius: 32px;
    padding: 5px 8px 5px 15px;
    margin: 5px 10px 5px 0;
    display: flex;
    align-items: center;
    min-height: 40px;
    font-weight: normal;
  }

  &__overflow-item-remove {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__phantom-text {
    position: relative;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;

    span {
      position: absolute;
      height: 0;
      top: 0;
      left: 0;
    }
  }

  &--compact {
    background-color: $g-gray-color-6;
    padding: 16px;
  }

  &--compact & {
    &__input {
      min-height: 53px;
      padding: 5px 12px;
      border: 1px solid $g-dark-color-3;
    }

    &__input-content {
      min-height: 30px;
    }

    &__selected {
      padding: 0;

      input {
        height: 45px;
        min-width: 336px;
        font-size: 14px;
      }
    }

    &__suggestions-anchor {
      position: relative;
    }

    &__suggestions {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      box-shadow: $common-box-shadow;
      border: 1px solid $g-dark-color-3;
    }

    &__suggestions-item {
      padding: 5px 27px;
    }

    &__overflow-item-suggestion {
      min-height: 35px;
    }
  }

  &--mobile {
    position: relative;
  }

  &--mobile & {
    &__input {
      border-radius: 0;
      background-color: #fcfcfc;
      box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
      border-bottom: 1px solid $g-dark-color-3;
      padding: 16px 12px;

      input {
        font-size: 16px;
        height: 30px;
        text-overflow: ellipsis;
        min-width: 300px;

        width: 100%;
      }
    }

    &__input-content {
      min-height: 40px;
    }

    &__overflow-item-suggestion {
      min-height: 32px;
      font-size: 13px;
    }

    &__suggestions {
      box-shadow: none;
    }

    &__suggestions-item {
      padding: 8px 27px;
    }

    &__suggestions-list {
      max-height: none;
    }

    &__selected {
      width: 100%;
      padding: 0 52px 0 34px;
    }

    &__mobile-back {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 21px;
      left: 12px;

      &--not-fixed {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__mobile-search {
      height: 40px;
      width: 40px;
      margin-left: auto;
      border-radius: 50%;
      background-color: $g-brand-color-1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 16px;
      right: 12px;
    }
  }
}
