.bill-pay-info {
  padding: 40px;

  &__sections {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__section-payment-info {
    width: 45%;
    border: 1px solid $g-dark-color-3;
    border-radius: 5px;
    padding: 32px;
  }
  &__payment-message {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .StripeElement {
    margin-bottom: 10px;
  }
}
