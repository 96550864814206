.ant-table-thead > tr > th {
  background: #00b2a312;
  white-space: normal;
}

.ant-btn-circle {
  min-width: 32px !important;
  border-radius: 50% !important;
  min-height: auto !important;
  font-size: 16px !important;
}

.ant-btn {
  font-weight: 600;
  font-size: 11px;
  border-radius: 5px;
  min-width: 90px;
  min-height: 35px;
}

.ant-modal-title {
  font-size: $g-font-size-heading;
  font-weight: normal;
}

.ant-modal,
.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-close {
  padding: 5px;
  height: 80px;
}
.ant-modal-header {
  padding: 16px;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid $g-dark-color-3;
  text-align: center;

  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-form-item {
  &:last-child {
    margin-bottom: 0;
  }
}

.ant-modal-body {
  padding: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  border-radius: 5px;
}

.ant-avatar {
  border: 1px solid $g-gray-color-4;
}

.ant-popover-inner-content {
  padding: 0px !important;
}

.ant-tooltip-inner {
  border-radius: 10px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #14142b;
}

.ant-tabs-ink-bar {
  background-color: #14142b;
}
