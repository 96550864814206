.l-group-members {
  padding: 20px;
  min-height: 100px;

  &__add-member-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}

.l-members-info {
  &__wrapper {
    display: flex;
    align-items: center;
    pointer-events: all;
  }

  &__action-btn {
    cursor: pointer;
    pointer-events: all;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid $g-brand-color-1;
    color: $g-brand-color-1;
    width: 70px;
    text-align: center;

    &--ban {
      color: $g-error;
      border-color: $g-error;
    }
  }

  &__add-member-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__list-container {
    max-height: 400px;
    height: auto;
    overflow-y: auto;
  }

  &__header {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 20px;
  }
}

.l-channel-list {
  height: 44%;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
  }

  &__header-icon {
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: $g-trans-base;

    &:hover {
      color: $g-brand-color-1;
      fill: $g-brand-color-1;
    }
  }

  &__header-title {
    font-size: 14px;
    font-weight: 300;
  }

  &__list-item {
    display: flex;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 15px;
    cursor: pointer;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $g-brand-color-1;
    }

    &--selected {
      color: $g-brand-color-1;
    }

    &--space-btw {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &--emp {
      padding: 8px 10px;
      margin-bottom: 0;

      &:hover {
        cursor: default;
        background: rgb(248, 248, 248);
      }
    }
  }

  &__more {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: $g-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  &__more-option {
    font-size: 13px;

    &--ban {
      color: red;
    }
  }

  &__list-item-actions {
    display: flex;

    > div {
      margin-left: 10px;
    }
  }

  &__list-item-avatar {
    margin-right: 15px;
  }

  &__list-item-name {
    line-height: 1.1;
  }

  &__list-item-sub-detail {
    font-size: 12px;
    color: $g-dark-color-5;
    text-transform: capitalize;
  }

  &__container {
    height: calc(100% - 45px);
    overflow-y: scroll;
  }
}
