.rich-text {
  &__editor-wrapper {
    background: $g-gray-color-5;
    height: auto;
    border-radius: 6px;
    // position: relative;
    width: 100%;
    border: 1px solid $g-dark-color-3;
  }
  &__editor {
    height: auto;
    min-height: 50px;
    max-height: 100px;
    overflow-y: scroll;
    padding: 8px;
  }
  &__style-btn {
    margin-right: 15px;
    color: $g-dark-color-5;
    font-size: 18px;
    cursor: pointer;

    &--active {
      color: $g-dark-color-4;
      svg,
      path {
        fill: $g-dark-color-4 !important;
      }
    }
  }
  &__top-controls {
    margin-bottom: 5px;
    position: sticky;
    background: rgba(239, 240, 247, 0.7);
    padding: 8px;
  }
  &__control-divider {
    border-left: 1px solid $g-border-color;
    margin-right: 15px;
  }
  &__bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bottom-left-controls {
    display: flex;
    align-items: center;
  }
  &__bottom-control {
    display: block;
    cursor: pointer;
    padding: 8px;
    padding-bottom: 5px;
    line-height: 1;
    height: 38px;
    font-size: 25px;
    .chat-emoji-button {
      font-size: 27px;
      line-height: 0.1;
      height: 24px;
    }
  }
  &__bottom-ant-select {
    line-height: 1;
    height: 24px;
  }
  &__file-preview-container {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  &__file-preview-card {
    margin-right: 4px;
    .ant-card-body,
    img {
      height: 100%;
      width: 100%;
    }

    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .anticon {
      font-size: 60px;
    }

    &:hover {
      .rich-text__file-operations {
        visibility: visible;
        opacity: 1;
        background: rgb(0, 0, 0, 0.4);
      }
    }
  }
  &__file-operations {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: $g-trans-base;
    border-radius: 4px;
  }
  &__file-delete-option {
    cursor: pointer;
    height: 30px;
    width: 30px;
    // border: 1px solid $g-white;
    // border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-white;
    opacity: 1;
    .anticon-delete,
    .anticon-eye {
      font-size: 16px !important;
    }
  }
}

.l-mention {
  width: 300px;
  display: flex;
  align-items: center;

  &__details {
    display: flex;
    align-items: center;
  }

  &__handle {
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 8px;
  }

  &__full-name {
    font-size: 14px;
    margin-left: 8px;
  }
}

.rich-text__editor {
  [role='option'] {
    display: flex !important;
    padding: 10px !important;
  }
}

.mention {
  color: #4a85bb;
  text-decoration: none;
}

.chat-emoji {
  display: block;
  display: flex;
  padding-bottom: 5px;
  line-height: 1;
  height: 38px;
  .e13wg9oj {
    display: none;
  }
}

.chat-emoji-popup {
  bottom: 60px;
  left: 0;
  margin-top: 10px;
  padding: 0 0.3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
}

.DraftEditor-root {
  overflow: auto;
}
