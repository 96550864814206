.c-estimate-gauge {
  background-color: #f7f7fc;
  border: 1px solid $g-dark-color-3;
  font-size: 14px;
  padding: 20px 20px 14px;
  margin: 8px;
  flex: 1 1;
  border-radius: 12px;
  display: flex;

  &__key-name {
    font-size: 18px;
    font-weight: bold;
  }

  &__value {
    font-size: 34px;
    margin-top: auto;
    font-family: $g-font-heading;
    font-weight: bold;
  }

  &__values {
    flex: 1 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
  }

  &__gauge {
    flex: 1 1;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

    > span {
      text-transform: uppercase;
    }
  }

  &__progress {
    position: relative;
    float: left;
    text-align: center;
    width: 80%;
  }

  &__overflow {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 50%;
    overflow: hidden;
  }

  &__bar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100%;
  }

  &__bar {
    border: 10px solid #e5e5e5;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: content-box;
  }

  &__needle {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -6px;
    transform-origin: bottom;

    > svg {
      z-index: 2;
      position: relative;
      display: block;
      margin-bottom: -5px;
    }

    &:after {
      position: absolute;
      bottom: -11px;
      left: 50%;
      margin-left: -12px;
      content: '';
      height: 24px;
      width: 24px;
      background-color: #e5e5e5;
      border-radius: 50%;
      z-index: 1;
    }
  }
}
