.l-suburb-share {
  padding: 1.7143rem;

  iframe {
    width: 100%;
  }

  &__error {
    color: #e61b00;
    width: 100%;
    font-size: 0.9286rem;
    text-align: center;
    margin-bottom: 1.1429rem;
  }

  &__close {
    cursor: pointer;
    padding: 0.2143rem;
    display: flex;
    height: 1.8571rem;
    width: 1.8571rem;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0.3571rem;
    border-radius: 50%;

    position: absolute;
    top: 62%;
    right: 44%;
    margin-right: -0.9286rem;
    margin-top: -0.9286rem;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__footer {
    padding: 1.1429rem;
    border-top: 0.0714rem solid $g-gray-color-6;
  }

  &__button-group {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .h-muted {
    color: $g-dark-color-5;
    text-transform: uppercase;
    font-weight: normal;
  }

  &__button {
    background: $g-brand-color-1;
    min-width: 11.0714rem;
    color: $g-white;

    height: 3rem;
    border-radius: 1.5714rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9286rem;
    width: 100%;

    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 8px;
    }
  }

  .ant-radio-button-wrapper {
    width: 100px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    border-color: transparent;
    height: 40px;
    font-size: 14px;
    box-shadow: none;
  }

  .ant-radio-group {
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}
