.l-update-order-summary {
  position: relative;
  border: 1px solid $g-dark-color-3;
  border-radius: 5px;
  width: 480px;
  padding: 32px;
  letter-spacing: 0.75px;
  &__heading {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.75px;
    color: $g-black;
  }
  &__plan-row {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: $g-gray-color-2;
  }
  &__frequency {
    font-weight: 400;
    font-size: 15px;
    color: #14142b80;
  }
  &__prorate-row {
    display: flex;
    justify-content: space-between;
    // font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $g-gray-color-2;
  }
  &__footer-message {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: $g-gray-color-1;
    margin-bottom: 20px;
  }

  &__btn {
    border-radius: 22px;
    height: 42px;
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: $g-offwhite;
  }

  &__promo-wrapper {
    display: flex;
    svg {
      margin-right: 5px;
    }
    path {
      stroke: $g-success;
    }
  }
}
