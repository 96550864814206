.l-create-channel-modal {
  padding: 20px;
  font-size: 15px;
  position: relative;
  &__info {
    margin-bottom: 20px;
    color: $g-gray-color-1;
  }
  &__field-wrapper {
    margin-bottom: 20px;
  }
  &__submit-btn {
    width: 100%;
    border-radius: 30px;
    height: 56px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
    font-family: $g-font-base;
  }
}
