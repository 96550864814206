.c-float-label {
  position: relative;

  &__label {
    font-size: 15px !important;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 13px;
    top: 12px;
    transition: 0.2s ease all;
    color: #6e7191 !important;

    &--float {
      top: 6px;
      font-size: 13px !important;
    }
  }
}
