.l-members {
  .ant-table-content {
    .ant-table-thead {
      background: $g-light-color-1;
    }
    .ant-table-thead > tr > th {
      background: transparent;
      color: $g-gray-color-1;
      font-weight: $g-font-weight-heading-base;
      font-size: 13px;
      letter-spacing: 0.25px;
      line-height: 16px;
    }
  }

  &__member-info-wrapper {
    display: flex;
    align-items: center;
  }

  &__member-avatar {
    margin-right: 10px;
  }

  &__member-name {
    font-size: 15px;
    line-height: 24px;
    color: $g-dark-color-2;
    letter-spacing: 0.75px;
  }

  &__member-email {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $g-gray-color-1;
  }

  &__members-invite-btn {
    width: 104px;
    height: 42px;
    border: 2px solid $g-brand-color-1;
    border-radius: 22px;
    font-weight: 600;
    font-size: 13px;
    color: $g-brand-color-1;
    padding: 9px 16px;
    pointer-events: all;
    cursor: pointer;
  }

  &__add-users-section {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 20px;
  }
  &__add-users-label {
    font-size: 15px;
    line-height: 24px;
    color: $g-dark-color-2;
    letter-spacing: 0.75px;
  }
  &__add-users-info-wrapper {
    transition: $g-trans-base;
    &:hover {
      padding-left: 15px;
    }
  }
  &__settings-menu {
    width: 180px;
    z-index: 99;
  }

  &__settings-menu-list-item {
    height: 54px;
    border-bottom: 1px solid #eee;
    display: flex;
    cursor: pointer;
    // justify-content: center;
    align-items: center;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 13px;
    transition: $g-trans-base;
    &:hover {
      background: $g-light-color-1;
    }
  }

  &__add-users-details {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $g-brand-color-5;
  }

  &__add-users-info-caret {
    path {
      stroke: $g-brand-color-1;
    }
  }
}
