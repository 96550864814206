.l-hashtag-options {
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  display: flex;

  &__option {
    margin: 0 12px 12px 0;
    padding: 8px 14px;
    color: #6e7191;
    border-radius: 12px;
    height: 40px;

    transition: 0.2s ease-in-out;
    font-size: 13px;
    font-weight: bold;
    background-color: $g-white;
    display: flex;
    align-items: center;

    &:hover {
      // background: $g-transparent-white;
      opacity: 0.85;
      cursor: pointer;
    }

    &--active {
      background: $g-brand-color-1;
      color: $g-white;
      font-weight: normal;
    }
  }
}

.l-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;

  .l-slider {
    background-color: $g-white;
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 16px;
    width: 100%;
    height: 40px;

    &__label {
      color: #6e7191;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      display: flex;
    }

    &__mark-label {
      color: #6e7191;
      font-weight: normal;
      min-width: 70px;
      font-size: 14px;
      display: block;
      text-align: left;
      margin: 0 0 0 10px;

      &:first-child {
        text-align: right;
        margin: 0 10px 0 0;
      }
    }

    &__active-label {
      font-weight: bold;
      font-size: 20px;
      display: block;
      text-align: center;
      width: 100px;
      display: flex;
      white-space: nowrap;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 422px;
      width: 100%;
    }
  }
}
