$font: $g-font-base;

@mixin label_transform_placeholder {
  font-size: 12px;
  transform: translateY(-20px);
}

@mixin transition-effect($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin custom-placeholder(
  $color: #000,
  $font-size: 14px,
  $font-family: 'DINRegular'
) {
  &::-webkit-input-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &::-moz-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &:-moz-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &:-ms-input-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
}

.slide-up-label-1 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $g-light-color-1;
  min-height: 56px;
  border-radius: 12px;
  padding: 20px 12px 2px 12px;

  &--error {
    border: 1px solid $g-error;
  }

  .label-el {
    position: absolute;
    top: 22px;
    left: 12px;
    display: inline-flex;
    align-items: center;
    font: normal 15px $font;
    color: $g-gray-color-1;
    @include transition-effect(0.4s);
    cursor: text;
    &--contains-element {
      @include label_transform_placeholder;
    }
  }

  .tag-container {
    display: flex;
    align-items: center;
    height: 56px;
    margin: 0;
    &--contains-element {
      padding: 12px 0px 2px 10px;
    }
  }

  .tag-element {
    display: inline-flex;
    padding: 2px 10px;
    background-color: #cecece;
    border-radius: 32px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      height: 10px;
      width: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .chip-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0px 12px 2px 2px;
    border-radius: 12px;
    font: normal 16px $font;
    color: $g-gray-color-2;
    background-color: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    cursor: text;
    transition: $g-trans-base;

    @include custom-placeholder($g-gray-color-1, 15, $font);

    &--empty {
      padding: 0px 12px 2px 2px;
      width: 100%;
    }
    &--contains-chips {
      max-width: 300px;
      width: auto;
    }

    &:focus ~ label {
      @include label_transform_placeholder;
    }
    &:not(:placeholder-shown) + label {
      @include label_transform_placeholder;
    }
  }
}
