.l-chat-panel {
  width: 300px;
  max-width: 300px;
  background: $g-light-color-1;
  height: 100%;

  @include bpm-sm {
    max-height: calc(100vh - 80px);
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
  }

  .ant-collapse-content-box {
    padding: 0px;
    max-height: 400px;
    overflow-y: scroll;
  }

  &__channel-options-popover {
    z-index: 1;
    pointer-events: all;
  }
  &__channel-popover-options-wrapper {
    padding: 5px 0px;
  }
  &__channel-popover-option {
    padding: 10px 24px;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    transition: $g-trans-base;
    &:hover {
      background: $g-dark-color-3;
    }
  }
  &__header {
    height: 70px;
    font-weight: 600;
    font-size: 25px;
    display: flex;
    align-items: center;
    font-family: $g-font-heading;
    padding: 25px;
    color: $g-font-heading-color;
  }
  &__collapse {
    background: $g-light-color-1;
  }
  &__colap-panel {
    border-bottom: none;
  }
  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $g-font-base;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px 15px;
    &:hover {
      background: rgba(217, 219, 233, 0.5);
    }

    &--selected {
      background: rgba(217, 219, 233, 0.5);
    }
  }
  &__list-info-wrapper {
    display: flex;
    align-items: center;
  }
  &__list-avatar {
    margin-right: 10px;
  }
  &__list-info {
    max-width: 160px;
  }
  &__list-label {
    font-size: 15px;
    font-weight: 400;
    color: $g-dark-color-2;
    @extend %text-ellipsis-placeholder;
  }
  &__list-sub-label {
    font-size: 12px;
    font-family: $g-google-Inter-font;
    color: $g-gray-color-1;
    @extend %text-ellipsis-placeholder;
  }
  &__list-msg-count {
    background: $g-brand-color-1;
    border-radius: 8px;
    padding: 4px 8px;
    max-height: 24px;
    max-width: 30px;
    font-size: 10px;
    font-family: $g-google-Inter-font;
    color: $g-tgray-color-7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
