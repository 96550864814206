.l-list {
  position: relative;
  flex: 1 0 100%;
  transition: max-width 0.3s ease 0.1s;

  &--default {
    max-width: calc(50vw - 200px);
  }

  &--compact {
    max-width: 432px;
  }

  &--wide {
    max-width: calc(66.6vw - 200px);
  }

  &--hidden {
    max-width: 0;
    overflow: hidden;
  }

  &--hidden & {
    &__body {
      width: 432px;
    }
  }

  &--compact & {
    &__body {
      min-width: 432px;
    }
  }

  &__body {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }

  &__search-bar {
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $g-white;
    border: 1px solid $g-dark-color-3;
    margin-right: 10px;
  }

  &__query-values {
    font-weight: bold;
    margin: 0 16px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  &__search-bar-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  &__button-common {
    width: 100%;
    height: 42px;
    background: $g-brand-color-1;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-white;
    font-size: 13px;
    margin-top: 18px;
    padding: 0 16px;

    &--light {
      border: $g-dark-color-3 1px solid;
      font-weight: bold;
      color: $g-brand-color-1;
      background-color: $g-white;
      margin-top: 0;
      white-space: nowrap;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }

      > span {
        margin-left: 10px;
      }
    }
  }

  &__search-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 16px;
  }

  &__options-sort {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    svg {
      margin-right: 8px;
    }
  }

  &__content {
    list-style: none;
    padding: 0 10px 0 0;
    margin: 0;
    overflow-y: auto;

    @include bp-sm {
      max-height: calc(100vh - 348px);
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
      border-radius: 5px;
    }

    .ant-pagination-item {
      border: none;
      border-radius: 5px;
    }

    .ant-pagination-item-active {
      font-weight: bold;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 40px 0 20px;

    .ant-pagination-item {
      border-color: $g-dark-color-3;
    }
  }

  &--tablet-mobile {
    width: 100%;
    height: 100%;
  }

  &--tablet-mobile & {
    &__body {
      padding: 0 10px;

      @include bp-sm {
        padding: 0;
      }
    }

    &__content {
      // max-height: calc(100vh - 166px);

      @include bp-sm {
        max-height: calc(100vh - 288px);
      }
    }
  }
}

.l-property {
  background: $g-white;
  border-radius: 16px;
  box-shadow: $common-box-shadow;
  margin-top: 20px;
  overflow: hidden;

  .h-bold {
    font-weight: bold;
  }

  &:first-child {
    margin-top: 0;
  }

  &__heading {
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button,
    span {
      color: $g-brand-color-1;
    }
  }

  &__content {
    padding: 15px 25px;
  }

  &__favorite {
    border-radius: 50%;
    border: 1px solid $g-dark-color-3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    height: 36px;
    width: 36px;

    svg {
      height: 18px;
    }
  }

  &__tag-umv {
    display: inline-block;
    background-color: #dad7fc;
    border-radius: 6px;
    padding: 8px 10px;
    font-size: 13px;
    line-height: 13px;
    margin: 10px 0 14px;
  }

  &__tag-group {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: 14px;

    > li {
      margin-right: 10px;
    }
  }

  &__tag-details {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    padding: 0 10px;
    font-size: 12px;
    background-color: $g-gray-color-6;
    min-height: 30px;

    svg {
      margin-right: 4px;
      height: 18px;
      vertical-align: center;
    }
  }

  &__address {
    font-size: 14px;
  }

  &__tag-list {
    list-style: none;
    padding: 0;
    margin: 16px 0 0;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      > span {
        font-size: 13px;
      }

      small {
        color: #6e7191;
        margin-left: 8px;
      }
    }
  }

  &__carousel-wrapper {
    position: relative;
  }

  &__carousel-prev {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 10px;
    z-index: 1;
  }

  &__carousel-next {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 10px;
    z-index: 1;
  }

  &__carousel-slide {
    height: 348px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--empty {
      background-color: $g-gray-color-1;
      height: 348px;

      span {
        z-index: 1;
        font-size: 18px;
        color: #fff;
      }

      svg {
        height: 50px;
        width: 50px;
        opacity: 0.6;

        path {
          fill: white;
        }
      }
    }
  }

  &__view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;

    &::before {
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #333;
      opacity: 0.5;
      position: absolute;
    }

    span {
      z-index: 1;
      font-size: 18px;
    }

    svg {
      height: 50px;
      width: 50px;

      path {
        fill: white;
      }
    }
  }

  &__description {
    margin: 20px 0;
    padding: 20px 0;
    border: solid #d9dbe9;
    border-width: 1px 0;
    font-size: 14px;

    span {
      margin-top: 6px;
      display: block;
      font-size: 13px;
    }
  }
}

.l-metric-summary {
  background: $g-white;
  border-radius: 16px;
  margin-top: 20px;
  box-shadow: $common-box-shadow;

  &:first-child {
    margin-top: 0;
  }

  &__box {
    padding: 16px;
    border: 1px solid #dbe1e4;
    border-radius: 16px;
    margin: 16px 0;
    box-shadow: $common-box-shadow;
  }

  &__content {
    display: flex;
  }

  &__section {
    flex: 1 1;
    padding: 4px 16px;
    margin: 12px 0;

    &--border {
      border-left: $g-dark-color-3 1px solid;
    }
  }

  &__details {
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }

    &--muted {
      dt,
      dd {
        font-weight: normal;
        color: $g-dark-color-5;
      }
    }

    &--bold {
      dt,
      dd {
        font-weight: bold !important;
        color: $g-gray-color-2;
      }
    }
  }

  &__metric-name {
    color: #4e4b66;
    // font-weight: bold;
    font-weight: normal;
  }

  &__metric-value {
    color: #4e4b66;
    white-space: nowrap;

    &--green {
      color: #18bb0c !important;
    }

    &--red {
      color: #e61b00 !important;
    }
  }
}
