@import './chat_panel/chat_panel.style';
@import './channel_browse/channel_browse.style';
@import './components/create_channel/create_channel.style';
@import './chat_window/chat_window.style';
@import './components/direct_message/direct_message.style';
@import './components/group_members/group_members.style';
@import './components/invite_members/invite_members.style';
@import './components/channel_options/channel_options.style';
@import './editor/editor.style';

.l-chat {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
