.l-team-setup {
  position: relative;

  &__panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
  }

  &__panel {
    pointer-events: none;
    .ant-collapse-header {
      pointer-events: none;
    }
  }

  &__panel-hedaer-side {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &__panel-hedaer-usr-icon {
    background: $g-brand-color-10;
    margin-right: 12px;
    height: 27px;
    width: 27px;
    min-width: 27px !important;
    border: none;
    color: $g-brand-color-1;
    &:focus,
    &:active,
    &:hover {
      background: $g-brand-color-10;
      border: none;
    }
  }

  &__panel-hedaer-usr-num {
    font-size: 15px;
    color: $g-dark-color-2;
  }
  &__panel-hedaer-cancel-btn {
    width: 115px;
    height: 42px;
    border: 2px solid $g-error;
    border-radius: 22px;
    font-weight: 600;
    font-size: 13px;
    color: $g-error;
    padding: 9px 16px;
    pointer-events: all;
    cursor: pointer;
    &:active,
    &:hover,
    &:focus {
      border: 2px solid $g-error;
      color: $g-error;
    }
  }
  &__panel-hedaer-invite-btn {
    width: 115px;
    height: 42px;
    border: 2px solid $g-brand-color-1;
    border-radius: 22px;
    font-weight: 600;
    font-size: 13px;
    color: $g-brand-color-1;
    padding: 9px 16px;
    pointer-events: all;
    cursor: pointer;
  }

  &__select {
    width: 100%;
    border-radius: 12px !important;

    background-color: $g-light-color-1;
    .ant-select-selector,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 56px !important;
      border-radius: 12px !important;
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 56px !important;
    }
    .ant-select-selection-item {
      line-height: 56px !important;
    }
    .ant-select-selector {
      background-color: $g-light-color-1 !important;
      border: none !important;
    }
  }
  &__field-wrapper {
    margin-bottom: 32px;
    width: 100%;
    pointer-events: all;
  }
  &__field-label {
    color: $g-dark-color-2;
  }
  &__field-error {
    color: $g-error;
    margin-left: 10px;
  }
  &__show-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    justify-content: flex-end;
  }
}
