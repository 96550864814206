.l-update-card {
  &__card-field-wrapper {
    margin-bottom: 15px;
  }
  &__card-field-label {
    color: $g-dark-color-2;
  }
  &__card-form-wrapper {
    position: relative;
  }
  &__card-fields-wrapper {
    .StripeElement {
      border-radius: 5px;
      padding: 10px;
      background-color: $g-light-color-1;
      height: 56px;
      display: flex;
      flex-direction: column;
      padding: 12px 12px 2px 18px;
      border-radius: 12px;
      box-shadow: 0 0 0 100px rgb(241 241 241) inset !important;
    }
    // margin-bottom: 40px;
  }
  .AddressElement {
    border-radius: 5px;
    padding: 10px;
    background-color: $g-light-color-1;
    height: 56px;
    display: flex;
    flex-direction: column;
    padding: 12px 12px 2px 18px;
    border-radius: 12px;
  }
  &__footer {
    margin-top: 25px;
  }
}
