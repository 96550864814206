.l-template {
  &__content {
    max-width: 800px;
    margin: 0 auto;
  }

  &__header {
    padding: 20px 15px;
    background-color: $g-brand-color-1;
    text-align: center;

    h1 {
      font-weight: 600;
      color: white;
      font-style: normal;
      margin-bottom: 6px;
      margin-top: 0;
      padding: 0;
      font-size: 36px;
    }

    p {
      color: white;
      margin: 0;
      font-size: 14px;
    }
  }

  &__body {
    padding: 20px 20px 25px 0;

    height: 100%;
    overflow-y: auto;

    @include bp-md {
      padding: 30px 32px 30px 12px;
      max-height: 400px;
    }

    a {
      text-decoration: underline;
      font-weight: bold;
    }

    h2 {
      font-weight: normal;
      font-size: 36px;
      text-align: center;
    }

    h4 {
      font-weight: normal;
      padding-top: 10px;
      display: flex;

      > span {
        width: 30px;
      }
    }

    // h5 {
    //   color: $g-brand-color-1;
    // }

    p {
      margin-bottom: 22px;
    }

    ul {
      margin-left: 55px;
      margin-bottom: 22px;

      li {
        margin-bottom: 10px;
      }

      ul {
        padding-top: 10px;
        list-style-type: '— ';

        li {
          padding-left: 10px;
        }
      }
    }

    ol {
      margin-left: 35px;
      margin-bottom: 22px;
      margin-top: 22px;
      counter-reset: list;

      li {
        margin-bottom: 10px;
        list-style: none;
        position: relative;
        padding-left: 35px;

        &:before {
          content: '(' counter(list, lower-alpha) ')';
          counter-increment: list;
          position: absolute;
          top: 0;
          left: 0;
          width: 35px;
          height: 100%;
        }
      }

      ol {
        counter-reset: list2;

        li {
          &:before {
            content: counter(list2, lower-alpha) '.';
            counter-increment: list2;
          }
        }

        &.roman {
          li {
            &:before {
              content: counter(list2, lower-roman) '.';
              counter-increment: list2;
            }
          }
        }
      }
    }

    .h-indent {
      margin-left: 38px;
    }

    .h-underline {
      margin-bottom: 10px;
      display: block;
      text-decoration: underline;
    }

    .h-center {
      text-align: center;
    }

    .h-paragraph {
      position: relative;
      padding-left: 45px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 45px;
        height: 100%;
      }
    }
  }
}
