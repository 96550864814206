.full-page-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  background-color: $g-white;
  opacity: 0.8;

  &__message {
    font-weight: 500;
    margin-top: 2px;
    opacity: 0.85;
  }
}
