.l-update-plan {
  &__add-user-btn {
    margin-left: 20px;
    color: $g-brand-color-1;
  }

  .ant-input[disabled] {
    color: $g-gray-color-2;
  }
}
