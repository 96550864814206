$font: $g-font-base;

@mixin transition-effect($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin custom-placeholder(
  $color: #000,
  $font-size: 14px,
  $font-family: 'DINRegular'
) {
  &::-webkit-input-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &::-moz-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &:-moz-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
  &:-ms-input-placeholder {
    color: $g-gray-color-1;
    font: $font-size $font-family;
  }
}

.slide-up-label {
  position: relative;
  margin-bottom: 4px;

  textarea:not([type='button']):not([type='submit']) {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 56px;
    margin: 0;
    padding: 24px 12px 2px 18px;
    border-radius: 12px;
    box-shadow: 0 0 0 transparent;
    font: normal 15px $font;
    color: $g-gray-color-2;
    background-color: $g-light-color-1;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    cursor: text;
    transition: $g-trans-base;

    @include custom-placeholder($g-gray-color-1, 15, $font);

    ~ label {
      position: absolute;
      top: 20%;
      left: 18px;
      display: inline-flex;
      align-items: center;
      font: normal 15px $font;
      color: $g-gray-color-1;
      @include transition-effect(0.4s);
      cursor: text;
    }
    &:focus {
      border: 1px solid $g-dark-color-2;
    }

    &:focus ~ label {
      font-size: 12px;
      color: color;
      transform: translateY(-15px);
    }
    &:not(:placeholder-shown) + label {
      font-size: 12px;
      color: color;
      transform: translateY(-15px);
    }

    // &:focus ~ label,
    // &:valid ~ label {
    //   font-size: 12px;
    //   color: color;
    //   transform: translateY(-15px);
    // }
  }
}
