.l-pagination {
  display: flex;
  justify-content: center;
  padding: 20px 10px 0;

  .ant-pagination-item {
    border-color: $g-dark-color-3;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    border-radius: 5px;
  }

  .ant-pagination-item {
    border: none;
    border-radius: 5px;
  }

  .ant-pagination-item-active {
    font-weight: bold;
  }
}
