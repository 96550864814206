.c-mobile-content-sider {
  position: relative;
  transition: $g-trans-base;
  font-family: $g-font-base;
  background: $g-white;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 16px;
    border-bottom: 1px solid $g-dark-color-3;
    font-size: 24px;
    font-family: $g-font-heading;
  }
  &__header-left-items {
    display: flex;
    align-items: center;

    > div {
      line-height: 1;
    }
  }
  &__header-right-items {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
