.c-layout-sider {
  position: relative;
  transition: $g-trans-base;
  font-family: $g-font-base;
  background: $g-dark-color-1 !important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__collap-btn {
    padding: 0px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: -15px;
    top: 64px;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    svg {
      height: 12px;
      width: 12px;
    }
    &:hover {
      transform: scale(1.2);
    }
    @include bpm-sm {
      display: none;
    }
  }
  &__invite-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $g-brand-color-3;
    border-color: $g-brand-color-3;
    color: $g-brand-color-4;
    font-size: 15px;
    font-weight: 600;
    text-shadow: none;

    &:hover {
      background: $g-brand-color-3;
      color: $g-dark-color-1;
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .ant-menu-item {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-avatar {
    width: 20px;
    height: 20px;
  }
  &__bottom-menu-container {
    @include bpm-sm {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .c-layout-menu {
        width: auto;
        .ant-menu-item {
          color: $g-brand-color-3 !important;
        }
      }
    }
  }
}
