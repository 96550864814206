.l-plan-info-modal {
  .ant-modal-body {
    padding: 32px;
    background-color: $g-brand-color-1-lite;
    border-radius: 20px;
  }

  .ant-modal-close {
    padding: 32px 10px;
  }
  &__header {
    font-family: $g-font-heading;
    font-size: 36px;
    font-weight: $g-font-weight-bold;
    margin-bottom: 20px;
    color: $g-font-heading-color;
  }
  &__plans-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__plan-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid $g-gray-color-2;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
    background-color: $g-offwhite;
    border-radius: 16px;
    padding: 16px;
    max-width: 322px;
  }
  &__plan-name {
    font-size: 30px;
    font-weight: $g-font-weight-bold;
    font-family: $g-font-heading;
    color: $g-font-heading-color;
    margin-bottom: 10px;
  }
  &__plan-rate {
    &--rate {
      font-size: 36px;
      font-weight: $g-font-weight-bold;
      font-family: $g-font-heading;
      color: $g-font-heading-color;
    }
    &--freq {
      font-weight: 400;
      font-size: 15px;
      color: $g-gray-color-1;
    }
  }
  &__plan-inc-users {
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: $g-font-heading-color;
    margin-bottom: 10px;
  }
  &__plan-description {
    color: $g-font-heading-color;
    font-weight: 400;
    font-size: 15px;
  }
  &__plan-item {
    display: flex;
    align-items: center;
  }
  &__plan-desc {
    margin-left: 10px;
    font-weight: 600;
    font-size: 15px;
    color: $g-dark-color-2;
  }
}
