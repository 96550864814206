.l-success {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $g-white;
  &__wrapper {
    width: 580px;
    display: flex;
    flex-direction: column;
  }
  &__details-wrapper {
    padding: 48px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    border: 1px solid $g-brand-color-5;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__success-icon {
    font-size: 90px;
    color: $g-brand-color-1;
    margin-bottom: 20px;
  }
  &__details-title {
    // display: flex;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: $g-font-heading-color;
    font-family: $g-font-heading;
    margin-bottom: 15px;
  }
  &__details-sub-title {
    font-weight: 400;
    font-size: 15px;
    color: $g-dark-color-2;
    margin-bottom: 20px;
  }
  &__details-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }
  &__details-item-title {
    font-weight: 600;
    font-size: 15px;
    color: $g-dark-color-2;
  }
  &__details-item-value {
    color: $g-gray-color-1;
    font-size: 13px;
    font-weight: 400;
  }
  &__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &--center {
      justify-content: center;
    }
  }
  &__btn {
    height: 56px;
    padding: 16px 24px 16px 24px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 15px;
  }
}
