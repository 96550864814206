@import './sign_up/sign_up.style';
@import './profile/profile.style';
@import './invite/invite.style';
@import './favorite/favorite.style';
@import './notification/notification.style';
@import './settings/settings.style';

.l-common-form {
  $form-error-color: #ff4d4f;
  $form-success-color: #008a00;
  $form-pass-color-1: #005bd4;
  $form-pass-color-2: #ee6d00;

  display: flex;
  min-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;

  @include bp-md {
    padding: 30px 20px;
  }

  @include bp-lg {
    min-width: 1260px;
  }

  &__body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp-md {
      max-width: 50%;
      min-width: 50%;
    }

    @include bp-lg {
      max-width: 35%;
      min-width: 35%;
    }
  }

  &__hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 0;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    display: none;

    @include bp-md {
      display: block;
      max-width: 50%;
      min-width: 50%;
      padding-top: 54.6%;
      margin-left: 20px;
    }

    @include bp-lg {
      max-width: 35%;
      min-width: 35%;
      padding-top: 43.6%;
    }
  }

  &__fields {
    width: 432px;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-top: 20px;
      margin-bottom: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }

  &__divider {
    display: flex;
    color: $g-dark-color-5;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    &:before,
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $g-dark-color-3;
    }

    &:before {
      margin: 0 10px 0 0;
    }

    &:after {
      margin: 0 0 0 10px;
    }
  }

  &__member {
    p {
      text-align: center;
      margin-bottom: 30px;

      a {
        font-weight: bold;
      }
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;

    height: 48px;
    width: 100%;
    background: $g-brand-color-1;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;

    @include bp-md {
      height: 56px;
    }
  }

  // .ant-checkbox-inner,
  // .ant-checkbox-input {
  //   transform: scale(1.5) translate(3px, 0);
  // }

  // .ant-checkbox + span {
  //   padding-left: 20px;
  // }

  .ant-form label {
    font-size: 15px;
  }

  &__link {
    font-weight: bold;
  }

  &__password-state {
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 0;
    min-height: 0;
    opacity: 0;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;

    &--active {
      height: auto;
      min-height: 15px;
      opacity: 1;
    }

    &--too-weak {
      color: $form-error-color;
    }

    &--medium {
      color: $form-pass-color-1;
    }

    &--weak {
      color: $form-pass-color-2;
    }

    &--strong {
      color: $form-success-color;
    }
  }

  &__alert {
    text-align: center;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 20px;

    &--error {
      background-color: #fff2e9;
      color: #c86000;
    }

    &--success {
      background-color: #eaf9de;
      color: #008a00;
    }
  }

  &__textarea {
    border-color: #f7f7fc;

    &.ant-input {
      background-color: #f7f7fc;
      border-radius: 12px;
      height: 80px !important;
      // color: #6e7191 !important;
      font-size: 15px;
      box-sizing: content-box;
      padding: 25px 11px 8px;
      width: calc(100% - 24px) !important;
    }
  }

  &__input {
    border-color: #f7f7fc;

    &.ant-input {
      background-color: #f7f7fc;
      border-radius: 12px;
      height: 14px !important;
      // color: #6e7191 !important;
      font-size: 15px;
      box-sizing: content-box;
      padding: 25px 11px 8px;
      width: calc(100% - 24px) !important;
    }

    &.ant-input-password {
      background-color: #f7f7fc;
      border-radius: 12px;
      height: 50px !important;
      border-color: #f7f7fc;
      color: #6e7191 !important;

      input {
        font-size: 15px;
        background-color: inherit;
      }

      input::placeholder {
        color: #6e7191 !important;
        opacity: 1;
      }
    }
  }

  &__google {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid $g-dark-color-3;
    font-weight: bold;
    height: 56px;
    width: 100%;
    background: #fff;
    border-radius: 30px;
    color: #4e4b66;
    font-size: 14px;
    position: relative;
    margin-bottom: 20px;

    img {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 50%;
      margin-top: -14px;
      left: 24px;
    }
  }

  &__facebook {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #0078ff;
    font-weight: bold;
    height: 56px;
    width: 100%;
    background: #0078ff;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    position: relative;
    margin-bottom: 30px;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 24px;
    }
  }

  .ant-form-item-explain-error {
    font-size: 12px !important;
    margin-top: 8px !important;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-input-password-icon {
    display: flex;
    justify-content: center;
  }

  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }
}

.l-custom-result {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7fc;
  height: 100%;
  width: 100%;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    margin: 0 auto 20px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: $g-brand-color-1;
      }
    }
  }

  &__content {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
    width: 100%;
    max-width: 400px;
    margin: 10px;

    h4 {
      text-align: center;
      font-size: 26px;
    }

    p {
      text-align: center;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;

    height: 48px;
    width: 100%;
    background: $g-brand-color-1;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    margin-top: 20px;

    @include bp-md {
      height: 56px;
    }
  }
}
