.l-share-report-modal {
  &__close {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    right: 44%;
    margin-right: -13px;
    margin-top: -13px;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
  }
  &__subtitle {
    font-size: 17px;
    line-height: 28px;
    font-weight: $g-font-weight-heading-base;
    display: flex;
    padding: 4px;
    margin-bottom: 20px;
  }
  &__share-subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    padding: 4px;
    margin-bottom: 20px;
    color: $g-gray-color-1;
  }
  &__suburb-name {
    color: $g-gray-color-2;
    font-weight: 600;
  }

  &__link-wrapper {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3px 20px;
    background: $g-light-color-1;
    height: 64px;
    margin-bottom: 40px;
  }

  &__report-link-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: $g-gray-color-1;
  }

  &__report-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: $g-gray-color-2;
  }

  &__options-wrapper {
    margin-bottom: 20px;
    min-height: 100px;
  }

  &__btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__report-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 16px 24px;
    border-radius: 30px;
    height: 50px;
    width: 160px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    &--view {
      border-color: $g-brand-color-1;
      border-width: 2px;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: $g-brand-color-1;
    }
    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  }
  &__btn {
    padding: 20px 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    height: 72px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    width: 100%;
  }

  .ant-modal-header {
    justify-content: flex-start;
  }

  .ant-modal-title {
    font-family: $g-font-heading;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
}
