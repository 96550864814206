.l-search {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #d9e1ed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 24px;
  // height: calc(100vh - 80px);

  &:after {
    content: '';
    width: 200%;
    height: 100vh;
    background-color: #c7d3e5;
    z-index: 1;
    top: 55vh;
    left: -50%;
    position: absolute;
    transform-origin: 100% 100%;
    transform: rotate(-13.5deg);

    @include bp-sm {
      top: 20vh;
    }
  }

  @include bp-sm {
    height: calc(100vh - 80px);
    padding: 0 24px;
    align-items: center;
  }

  &__bg {
    position: absolute;
    bottom: 60px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    padding: 0 40px;

    svg {
      width: 100%;
      height: 100%;
      max-width: 670px;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 830px;
    margin-top: 45px;
    z-index: 3;

    h1 {
      font-size: 32px;
      max-width: 700px;
      color: #101828;

      @include bp-sm {
        font-size: 50px;
      }

      @include bp-lg {
        font-size: 60px;
      }
    }

    h4 {
      font-size: 17px;
      color: #667085;

      @include bp-sm {
        font-size: 21px;
      }

      margin-bottom: 25px;
      max-width: 700px;
      line-height: 1.6;
    }

    h1,
    h4 {
      font-weight: normal;
    }
  }
}

@media screen and (max-height: 760px) {
  .l-search {
    &__bg {
      display: none;
    }

    &__heading {
      margin-top: 120px;
    }
  }
}
