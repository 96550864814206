.l-bar {
  padding: 0 16px;
  min-height: 76px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $g-white;
  border: solid $g-dark-color-3;
  border-width: 0 0 1px;
  margin-bottom: 10px;

  border-radius: 0;
  background-color: #fcfcfc;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
  padding: 15px 12px;

  &__back {
    margin-right: 4px;
  }

  @include bp-sm {
    border-radius: 10px;
    background-color: $g-white;
    box-shadow: none;
    padding: 16px;
    border-width: 1px;
    margin-bottom: 0;
  }

  &__query-values {
    font-weight: bold;
    margin: 0 16px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  &__search-bar-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  &__button-common {
    min-width: 42px;
    height: 42px;
    background: $g-brand-color-1;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-white;
    font-size: 13px;
    margin-top: 18px;

    @include bp-sm {
      padding: 0 16px;
    }

    &--padded {
      padding: 0 16px;
    }

    &--light {
      border: $g-dark-color-3 1px solid;
      font-weight: bold;
      color: $g-brand-color-1;
      background-color: #fcfcfc;
      margin-top: 0;
      white-space: nowrap;
      margin-left: 8px;

      @include bp-sm {
        background-color: $g-white;
      }

      &:first-child {
        margin-left: 0;
      }

      > span {
        margin-left: 10px;
      }
    }
  }

  &__search-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
