@import './plan_information/plan_information.style';
@import './summary/summary.style';
@import './components/section_components/section_components.style';
@import './payment_information/payment_information.style';
@import './review/review.style';
@import './payment_success/payment_success.style';

.l-billing {
  width: 100%;
  height: 100%;
  background: #fcfcfc;
  position: relative;

  &__tab-icon {
    background: #fcfcfc;
    color: $g-brand-color-5;
    font-weight: 600;
    border-color: $g-brand-color-5;
  }
  .ant-tabs-ink-bar {
    background-color: $g-brand-color-1;
  }
}

.ant-tabs-tab-active {
  .l-billing {
    &__tab {
      color: $g-brand-color-1;
    }
    &__tab-icon {
      color: $g-brand-color-1;
    }
  }
}
