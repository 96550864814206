$common-transition: all 0.1s ease-in-out;
$common-box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);

@import './form/search/search.style';
@import './form/search/search_loader/search_loader.style';
@import './form/search/search_field/search_field.style';
@import './form/search/save_search/save_search_modal/save_search_modal.style';
@import './form/search/share_suburb/share_suburb.style';
@import './form/filter/filter_modal/filter_modal.style';
@import './form/filter/filter_field/filter_field.style';
@import './results/list/list.style';
@import './results/pagination/pagination.style';
@import './results/bar/bar.style';
@import './results/list/sort/sort_modal/sort_modal.style';
@import './common/map/map.style';
@import './view/details/details.style';
@import './common/heading/heading.style';
@import './common/map/map_style_picker/map_style_picker.style';
@import './common/toggler/toggler.style';
@import './view/property_details/property_details.style';
@import './view/property_details/estimate_gauge/_estimate_gauge.style';
@import './view/property_details/lightbox/_lightbox.style';
@import './common/report/report.styles';

::placeholder {
  font-weight: normal !important;
}

.l-search-main {
  display: flex;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;

  &--padded {
    @include bp-sm {
      padding: 34px 30px;
    }
  }

  &__tablet-mobile-wrapper {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
  }

  &__close {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }
}

.ant-notification-notice {
  border-radius: 10px;
}

.ant-notification-notice-error {
  border-left: 5px solid #e61b00;
}

.ant-notification-notice-warning {
  border-left: 5px solid #ff9943;
}

.ant-notification-notice-success {
  border-left: 5px solid #18bb0c;
}

.ant-notification-notice-message {
  font-size: 14px !important;
  font-weight: bold;
}

.custom-pagination {
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    margin-right: 0;
    border-radius: 0;
    background-color: transparent;

    height: 30px;
    width: 30px;
    line-height: 28px;

    @include bp-sm {
      height: 40px;
      width: 40px;
      line-height: 38px;
    }
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    border: solid $g-dark-color-2;
    border-width: 1px;
    margin-left: -1px;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: transparent;
    }
  }

  .ant-pagination-prev {
    border-radius: 8px 0 0 8px;
  }

  .ant-pagination-next {
    border-radius: 0px 8px 8px 0px;
  }

  .ant-pagination-item {
    > a {
      padding: 0;
    }
  }

  .ant-pagination-item-active {
    background-color: $g-dark-color-2;

    > a {
      color: $g-white !important;
    }
  }
}
