.c-invite-form {
  &__wrapper {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      width: 100%;
    }
  }
  &__description {
    color: $g-gray-color-1;
    font-weight: 400;
    margin-bottom: 20px;
  }
  &__share-title {
    color: $g-black;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  }
  &__share-btns-wrapper {
    width: 100%;
  }
  &__error {
    font-size: 13px;
    color: #eb5757;
    margin-bottom: 16px;
  }
  &__share-btn {
    border-radius: 32px;
    width: 100%;
    height: 56px;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--email {
      border-radius: 12px;
    }
    &--facebook {
      background: $g-facebook-blue;
      border-color: $g-facebook-blue;
      &:hover,
      &:focus {
        background: lighten($color: $g-facebook-blue, $amount: 6);
        border-color: $g-facebook-blue;
      }
    }
    svg {
      margin-right: 10px;
    }
  }
}
