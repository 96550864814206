.l-acc-settings {
  position: relative;
  margin-top: 20px;
  &__section {
    display: flex;
    width: 100%;
  }
  &__settings-options {
    margin-left: 70px;
    width: 100%;
    cursor: pointer;
  }

  &__settings-title {
    color: $g-dark-color-2;
    font-weight: $g-font-weight-heading-base;
    font-size: $g-font-size-base;
    letter-spacing: 0.75px;
    width: 400px;
  }

  &__settings-option {
    display: flex;
    margin-bottom: 40px;
    font-size: 13px;
    color: $g-dark-color-2;
    letter-spacing: 0.25px;
    width: 100%;
    justify-content: space-between;
    transition: $g-trans-base;
    &:hover {
      padding-left: 10px;
    }
  }

  &__settings-option-icon {
    path {
      stroke: $g-brand-color-1;
    }
  }

  &__settings-option-desc {
    font-size: 13px;
    letter-spacing: 0.25px;
    color: $g-brand-color-5;
    line-height: 16px;
  }
}
