.l-org-setup {
  position: relative;
  &__field-wrapper {
    margin-bottom: 32px;
    width: 100%;
  }
  &__field-title {
    font-weight: $g-font-weight-heading-base;
    font-size: 20px;
    color: $g-font-heading-color;
    margin-bottom: 20px;
  }
  &__dragger {
    padding: 32px;
  }
  &__dragger-text {
    margin-bottom: 32px;
    color: $g-gray-color-2;
    font-size: 18px;
  }
  &__dragger-btn {
    border-radius: 22px;
    font-weight: $g-font-weight-heading-base;
    font-size: 13px;
    // padding: 9px 16px;
    height: 42px;
    width: 150px;
  }
  &__img-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background: $g-offwhite;
    border-radius: 8px;
  }
  &__img-left-side {
    display: flex;
    align-items: center;
  }
  &__img-wrapper {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-right: 15px;
    img {
      border-radius: 8px;
    }
  }
  &__detail-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__detail-name {
    font-size: 15px;
    color: $g-gray-color-2;
  }
  &__detail-size {
    font-size: 13px;
    color: $g-gray-color-1;
  }
  &__img-right-side {
    color: $g-gray-color-1;
    cursor: pointer;
    pointer-events: all;
    svg {
      fill: $g-gray-color-1;
      stroke: $g-gray-color-1;
      path {
        stroke: $g-gray-color-1;
      }
    }
  }

  .ant-upload-drag {
    background: $g-gray-color-7;
    border-radius: 16px;
    border: none;
  }
}
