@import './suburb_map/suburb_map.style';
@import './suburb_details/suburb_details.style';

.l-report {
  &__org-picture {
    max-width: 110px;
    max-height: 150px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  &__powered-by {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $g-gray-color-1;
    margin-bottom: 30px;

    svg {
      margin-left: 10px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    h4 {
      margin-top: 4px;
      font-size: 20px;
      color: black;
      padding-bottom: 0;
      line-height: 1.1;
    }
  }

  &__tag {
    margin-left: 20px;
    text-transform: capitalize;
    padding: 4px 10px;
    background-color: $g-brand-color-10;
    border-radius: 6px;
  }
}

.l-report-main {
  padding-top: 40px;

  &__content {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 32px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
  }
}

.l-report-details {
  background-color: $g-gray-color-6;
  padding-bottom: 50px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 79px;
    background-color: white;
    border-bottom: 2px solid $g-dark-color-3;
  }

  &__content {
    position: relative;
    min-height: 100px;
    width: 1000px;
    margin: 0 auto;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $g-brand-color-1;
    }

    .ant-tabs-ink-bar {
      background-color: $g-brand-color-1;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 50px;
    }

    .ant-tabs-tab {
      width: 150px;
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
      padding: 15px 0;
      text-align: center;
      align-items: flex-end;
    }
  }

  .l-project-actions {
    &--report-margin {
      margin-left: auto;
    }

    &--report-width {
      max-width: 150px;
    }
  }
}
