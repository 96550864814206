@import '../components/onboard_success/onboard_success.style';

.l-onboarding {
  .ant-modal-body {
    padding: 32px;
    border-radius: 20px;
  }
  &__section-title {
    font-weight: $g-font-weight-bold;
    font-size: 36px;
    font-family: $g-font-heading;
    color: $g-font-heading-color;
    margin-bottom: 20px;
  }
  &__section-sub-title {
    font-weight: $g-font-weight-heading-base;
    font-size: 20px;
    color: $g-font-heading-color;
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__skip-btn {
    padding: 16px 24px;
    font-size: 15px;
    font-weight: $g-font-weight-heading-base;
    color: $g-brand-color-5;
    cursor: pointer;
  }
  &__save-btn {
    width: 185px;
    height: 56px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: $g-font-weight-heading-base;
  }
  &__section-info {
    font-size: 15px;
    color: $g-gray-color-1;
    margin-bottom: 20px;
  }
}
