@import './related_articles/related_articles.style';
@import './key_indicators/key_indicators.style';
@import './projects/projects.style';
@import './price_rent_forecasts/price_rent_forecasts.style';

.l-details {
  position: relative;
  flex: 1 0 100%;
  transition: max-width 0.3s ease 0.1s;

  &--default {
    max-width: calc(50vw - 200px);
  }

  &--compact {
    max-width: 432px;
  }

  &--wide {
    max-width: calc(66.6vw - 200px);
  }

  &--hidden {
    max-width: 0;
    overflow: hidden;
  }

  &--hidden & {
    &__body {
      width: 432px;
    }
  }

  &--compact & {
    &__body {
      min-width: 432px;
    }
  }

  &__body {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }

  &__go-back {
    padding: 10px 20px 10px 0;

    svg {
      margin-right: 10px;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  &__tabs {
    margin-top: 26px;

    .ant-tabs-tab-btn {
      color: $g-dark-color-5;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #14142b;
    }
  }

  &__tabs-body {
    height: calc(100vh - 376px);
    overflow-y: auto;
    padding-right: 10px;

    &--short {
      height: calc(100vh - 435px);
    }

    &--mobile {
      height: auto;
      overflow-y: visible;
    }
  }

  &__tab-label {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--tablet-mobile {
    width: 100%;
    margin-top: 10px;
    overflow-y: auto;
    min-height: calc(100vh - 173px);
  }

  &--tablet-mobile & {
    &__body {
      padding: 0 10px;

      @include bp-sm {
        padding: 0;
      }
    }

    // &__content {
    //   max-height: calc(100vh - 166px);

    //   @include bp-sm {
    //     max-height: calc(100vh - 288px);
    //   }
    // }
  }

  .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .ant-tabs-nav {
    margin-bottom: 32px;
  }

  .ant-tabs-tab {
    align-items: flex-end;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 34px;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    svg:not(.h-building) {
      path {
        stroke: $g-brand-color-1;
        stroke-width: 1.5;
      }
    }

    .h-building {
      path {
        fill: $g-brand-color-1;
      }
    }
  }
}

// Revisit
// Transparent
$g-transparent-black: #00000000;
$g-transparent-skyblue: #91d5ff88;
$g-transparent-blue: #0065c2bb;
$g-transparent-orange: #be8004ee;
$g-transparent-gray: rgba(225, 229, 230, 0.6);
$g-transparent-gray-2: #a0a8b244;
$g-transparent-gray-3: #89939f77;
$g-transparent-black: rgba(0, 0, 0, 0.9);
$g-transparent-white: rgba(255, 255, 255, 0.7);
$g-transparent-black-2: rgba(20, 20, 20, 0.88);

// Grayed/Toned-down shades
$g-tgray-color-1: #f1f5f7;
$g-tgray-color-2: #a0a8b2;
$g-tgray-color-3: #89939f;
$g-tgray-color-4: #f4f7f9;
$g-tgray-color-5: #aaaaaa;
$g-tgray-color-6: #f1f5f9;
$g-tgray-color-7: #fcfcfc;
$g-tgray-color-8: #dee7ed;
$g-tgray-color-9: #727e8d;
$g-tgray-color-10: #f5fcff;
$g-tgray-color-11: #555555;
$g-tgray-color-12: #fbfbfb;
$g-tgray-color-13: #9dadbc;

.about {
  .l-dashboard-card {
    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &--justify-start {
        justify-content: start;
      }
    }

    &__value {
      margin-right: 10px;
    }

    &--min-height {
      // min-height: 170px;
      min-height: 201px;
    }
  }

  .l-legend {
    padding: 1.4286rem 0 0;

    li:last-child {
      margin-bottom: 0;
    }
  }
}

.l-rental-market {
  position: relative;

  &__chart {
    position: relative;
    z-index: 2;
    padding: 0 20%;
  }

  &__chart-symbol {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2.1429rem;
  }
}

.l-main-industries {
  position: relative;
  max-width: 22.8571rem;
  margin: 0 auto;
  padding: 0.8571rem;

  &__chart {
    position: relative;
    z-index: 1;
  }

  &__highest {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__value {
    margin-bottom: 0.3em;
    color: $g-transparent-black;
    font-weight: 600;
    font-size: 2.1429rem;
    line-height: 1.35;
    padding-bottom: 0;
  }

  &__industry {
    color: $g-tgray-color-2;
    display: block;
  }
}

.l-other-industries {
  padding: 1.0714rem 2.5rem 2rem;
  margin: 0;
  list-style: none;
  background: $g-transparent-black-2;

  &__item {
    padding: 0;
    margin-top: 0.7143rem;
    vertical-align: middle;
    white-space: nowrap;

    &:first-child {
      margin-top: 0;
    }
  }

  &__key {
    color: rgba(255, 255, 255, 0.85);
    margin-right: 1.0714rem;
    font-size: 13px;
  }

  &__value {
    color: $g-white;
    font-weight: 600;
    font-size: 0.9286rem;
  }

  &__color {
    height: 0.7143rem;
    width: 0.7143rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.7143rem;
    background: #af8aff;
  }
}

// Revisit

.l-descriptor {
  padding: 16px 24px;
  // margin: 0 25px; // Revisit
  background: $g-transparent-black-2;
  color: #fffffff5;
  max-width: 380px;
  line-height: 1.8em;
  font-size: 13px;
  border-radius: 4px;
  &--wide {
    max-width: 500px;
  }
}

.l-descriptor-trigger {
  cursor: pointer;
  font-size: 20px;
  margin-left: 8px;
  margin-top: -6px;

  &:hover {
    svg {
      path {
        fill: #a799ff;
      }
    }
  }

  &--small {
    font-size: 12px;
    background-color: #ccc;
    color: #000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    font-weight: normal;
    margin-left: 4px;
  }
}

.l-dashboard-card {
  overflow: hidden;
  border-radius: 16px;
  background: $g-white;
  height: 100%;
  box-shadow: $common-box-shadow;

  &__header {
    padding: 16px;

    &--border {
      border-bottom: 1px solid $g-transparent-gray;
    }

    &--dark {
      background-color: #14142b;
      padding: 20px;
    }
  }

  &__body {
    padding: 16px;
    position: relative;

    &--no-padding {
      padding: 0 !important;
    }

    &--flex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    dl {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 0.5em;
    color: $g-transparent-black;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    padding-bottom: 1.2857rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #4e4b66;
    font-family: Poppins, sans-serif;

    &--no-padding {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &--dark {
      color: #fff;
      display: flex;
      flex-direction: column;

      strong {
        font-weight: normal !important;
      }
    }
  }

  &__title-type {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.7;
    margin-bottom: 5px;
  }

  &__value {
    margin-bottom: 0;
    margin-right: 2.5rem;
    color: $g-transparent-black;
    font-weight: 600;
    font-size: 2.1429rem;
    line-height: 1.35;
    padding-bottom: 0;
    display: inline-block;
    font-family: Comfortaa, sans-serif;
  }

  &__change-percent {
    font-size: 1.1429rem;
    white-space: nowrap;

    &--up {
      // color: $g-brand-color-3;
      color: #33b433;
    }

    &--down {
      // color: $g-brand-color-4;
      color: #fc5a5a;
    }

    &--zero {
      // color: $g-tgray-color-2;
      color: #92929d;
    }
  }

  &__change-direction {
    margin-left: 0.8571rem;
  }

  &__details {
    color: $g-tgray-color-2;
    margin-top: 0.3em;
  }

  &__label {
    color: $g-tgray-color-3;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0.8571rem;
  }

  &__actions {
    position: absolute;
    top: 10px;
    right: 16px;

    /* Override */
    .ant-select-selector {
      background-color: $g-tgray-color-4 !important;
      width: 100px !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 30px !important;
      padding: 0 1.0714rem !important;
    }
  }

  &__image {
    height: 17.8571rem;
    width: 100%;
    background: $g-tgray-color-13;
    transition: opacity 0.2s ease-in-out;
    display: block;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__actions & {
    &__label {
      margin-right: 1.0714rem;
    }
  }

  &__publisher {
    display: flex;
    align-items: center;
    margin-bottom: 1.4286rem;

    .img {
      background: $g-brand-color-5;
      border-radius: 50%;
      margin-right: 1.0714rem;
      height: 3.5714rem;
      min-width: 3.5714rem;
      max-width: 3.5714rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.7143rem;
      color: $g-white;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }

  &__article-link {
    font-size: 1.2857rem;
    margin-bottom: 1.7857rem;
    display: block;
    line-clamp: 3;
    color: #23005c;
    font-weight: bold;

    &:hover {
      color: #23005caa;
    }
  }

  &__bullet {
    height: 0.8571rem;
    width: 0.8571rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.7143rem;
  }

  &--emphasized {
    background: $g-brand-color-5;
  }

  &--emphasized & {
    &__title,
    &__value,
    &__change-percent,
    &__details {
      color: $g-white;
    }
  }

  &--flat {
    background: transparent;
    box-shadow: none;
    height: auto;
    border: 0.1429rem solid $g-transparent-gray-2;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--masonry {
    height: auto;
    margin-bottom: 15px;
  }
}

.l-legend {
  list-style: none;
  margin: 0;
  padding: 1.4286rem 0;

  &__item {
    padding: 0;
    margin-bottom: 0.7143rem;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__key {
    color: $g-transparent-black;
    margin-right: 1.0714rem;
  }

  &__value {
    color: #14142b;
    font-weight: 600;
  }

  &__info {
    font-size: 1.2857rem;
    margin-left: 0.5714rem;
    display: inline-block;
    vertical-align: baseline;
    line-height: 1;
    cursor: pointer;
  }

  &__color {
    height: 0.8571rem;
    width: 0.8571rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.7143rem;
  }

  &--horizontal {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &--horizontal & {
    &__key {
      margin: 0;
    }

    &__color {
      margin-left: 2.1429rem;
      margin-right: 0.7143rem;
    }
  }

  &--projects {
    justify-content: flex-start;
    padding: 0 15px;
  }

  &--projects & {
    &__item {
      white-space: initial;
    }

    &__key {
      margin: 0;
    }

    &__color {
      margin-left: 0;
      margin-right: 0.7143rem;
    }
  }
}

.l-placeholder-alert-list {
  list-style: none;

  li {
    &::before {
      content: '\2022';
      color: $g-tgray-color-5;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font-size: 15px;
    }
  }
}

.c-article-nav {
  font-size: 16px;
  cursor: pointer;
  color: $g-brand-color-2;
  transition: 0.2s ease-in-out;
  padding: 0;
  margin-top: 16px;

  span {
    margin: 0 8px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
  }

  i {
    font-size: 20px;
    display: inline-block;
    // vertical-align: middle;
  }

  &:hover {
    color: $g-brand-color-5;
  }

  &--right {
    text-align: right;
  }
}

.h-text {
  &--muted {
    color: $g-tgray-color-2;
  }

  &--positive {
    color: #33b433;
  }

  &--negative {
    color: $g-brand-color-4;
  }

  &--emphasized {
    color: $g-brand-color-5;
  }

  &--name {
    margin: 0;
    padding-bottom: 0.3571rem;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--enlarge {
    font-size: 1.1429rem;
  }

  &--label {
    color: $g-transparent-black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0.8571rem;
  }
}

.h-image {
  &--table {
    background: $g-tgray-color-6;
    height: 90px;
    width: 150px;
    border-radius: 0.5rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

.h-bullet {
  &--pull-up {
    margin-right: 7px;
    display: inline-block;
    margin-top: -3px;
  }
}

.h-override {
  &--select-bg {
    .ant-select-selector {
      background-color: $g-tgray-color-4 !important;
    }
  }

  &--checkbox-group {
    .ant-checkbox-group {
      border-left: 2px solid $g-transparent-gray-3;
      margin-left: 7px;
      padding: 10px 0 10px 18px;

      > div {
        &:last-child {
          .ant-checkbox-wrapper {
            margin-bottom: 0;
          }
        }
      }

      > .ant-checkbox-wrapper {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &--hide-table-header {
    .ant-table-thead {
      display: none;
    }

    .ant-table-tbody > tr > td {
      vertical-align: top;
      padding: 1.4286rem 0.7143rem 1.4286rem 0 !important;

      &:first-child {
        padding-left: 1.4286rem !important;
        padding-right: 1.4286rem !important;
        // width: 17.1429rem;
        width: 150px;
      }

      &:last-child {
        padding-right: 1.4286rem !important;
      }
    }
  }

  &--hide-table-body {
    .ant-table-thead > tr > th {
      padding: 1.4286rem 0.7143rem 1.4286rem 0 !important;
      // border-bottom: 2px solid $g-tgray-color-2 !important;
      border-bottom: 0.1429rem solid $g-transparent-gray-2 !important;

      &:first-child {
        padding-left: 1.4286rem !important;
        padding-right: 1.4286rem !important;
        // width: 17.1429rem;
        width: 150px;
      }

      &:last-child {
        padding-right: 1.4286rem !important;
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-tbody {
      display: none;
    }
  }

  &--table-compact {
    .ant-table-tbody > tr > td {
      padding: 12px 0.7143rem 12px 0 !important;
    }
  }
}

/* Plugin */

.masonry-grid {
  display: flex;
  margin-left: -15px; /* Gutter size offset */
  width: auto;
  min-height: 1000px;
}
.masonry-grid_column {
  padding-left: 15px; /* Gutter size */
  background-clip: padding-box;
}

/* Overrides */

.l-tabs--overrides {
  .ant-card {
    border: none;
    border-radius: 0;
    border-radius: 0.5rem;
  }

  .ant-table-thead > tr > th {
    padding: 1.1429rem;
    color: $g-tgray-color-2;
    font-weight: bold;
    background: $g-tgray-color-1;
    text-transform: uppercase;
    font-size: 0.8571rem;
    border-top: 1px solid #e4ecf0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    // background: $g-brand-color-6;
    background: $g-white;
  }

  .ant-table-tbody > tr > td {
    padding: 1.4286rem;
    border-bottom: 1px solid $g-transparent-gray;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 6px;
    margin-right: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 3.5714rem;
    padding: 0.7143rem 1.0714rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 5px;
  }

  .ant-alert {
    border-radius: 16px;
    border-width: 1px;
    padding: 16px;

    &-info {
      background-color: $g-tgray-color-10;
      border-color: $g-transparent-skyblue;

      .ant-alert-message {
        color: $g-transparent-blue;
      }
    }

    &-warning {
      .ant-alert-message {
        color: $g-transparent-orange;
      }
    }

    .ant-alert-message {
      margin-bottom: 0;
      font-weight: normal;
    }

    &.ant-alert-with-description {
      .ant-alert-message {
        font-size: 1rem !important;
      }
    }

    ul {
      padding: 1rem 0.7143rem 0 1.0714rem;
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: $g-tgray-color-11;
    background: $g-tgray-color-12;
  }

  .l-dashboard-card {
    .ant-alert {
      border-width: 0;
      padding: 1.7857rem;
      box-shadow: none;
    }

    &__values-wrapper {
      .ant-alert {
        padding: 0.5714rem;
        font-weight: normal;
        box-shadow: none;
      }
    }
  }
}

.l-select-year {
  display: flex;
  padding: 0 !important;
  margin: 15px 0 0 !important;
  justify-content: center;
  align-items: center;

  &__label {
    font-weight: 600;
    font-size: 14px;
    list-style: none;
    padding: 0 !important;
    margin: 0 6px 0 0 !important;
  }

  @include bp-sm {
    margin: 0 !important;
  }

  &__option {
    list-style: none;
    cursor: pointer;
    padding: 5px 0 !important;
    border-radius: 12px;
    margin: 0 3px !important;
    min-width: 40px;
    color: #6c7886;
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    &:hover {
      color: #636f7c;
    }

    &--active {
      background: $g-brand-color-1;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    .h-text {
      display: none;
    }

    @include bp-sm {
      min-width: 50px;
      padding: 5px 10px !important;
      margin: 0 3px !important;

      .h-text {
        display: inline;
      }
    }
  }
}

.ant-popover-inner {
  box-shadow: none !important;
  border-radius: 0.5rem;
  overflow: hidden;
  // background-color: transparent !important; // Revisit
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-popover-title {
  padding: 2rem 2.5rem 0 !important;
  font-weight: normal;
  font-size: 16px;
  border: none;
  background: $g-transparent-black-2;
  color: $g-white;
}

.ant-popover-placement-top .ant-popover-arrow-content {
  box-shadow: none;
}

.ant-popover-arrow-content {
  // background: $g-transparent-black-2;

  &:before {
    background: $g-transparent-black-2;
  }
}

.ant-col.ant-form-item-label {
  min-width: 120px;

  > label {
    justify-content: flex-end;
  }
}

.ant-alert-error {
  border-radius: 7px;
  border-width: 0;
  padding: 1.5rem;
}

.tox-notifications-container {
  display: none !important;
}

/* Breakpoints */

// @media only screen and (min-width: 1650px) {
//   .l-dashboard-card {
//     &__header {
//       padding: 2rem 2.5rem;
//     }

//     &__body {
//       padding: 2rem 2.5rem;
//     }
//   }
// }
