.l-ai-report {
  width: 100%;
  background-color: $g-light-color-1;

  &__input {
    background-color: $g-gray-color-6;
    // box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 16px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  &__input-content {
    min-height: 57px;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    position: relative;
    border-radius: 12px;
  }

  &__selected {
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    display: flex;
    flex: 1 1;
    max-height: 138px;
    padding: 0 0 0 10px;

    input {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0 8px;
      outline: none;
      width: 100%;
      max-width: 100%;
      height: 40px;
      text-overflow: ellipsis;
      min-width: 350px;

      &[disabled] {
        opacity: 1;
        color: $g-dark-color-1;
      }
    }
  }

  &__overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
  }

  &__overflow-item-suggestion {
    border-radius: 32px;
    // padding: 5px 8px 5px 15px;
    // margin: 5px 10px 5px 0;
    // background-color: #f2f4f5;
    padding: 0 0 0 8px;
    display: flex;
    align-items: center;
    min-height: 39px;
    font-weight: normal;
    width: 100%; // One item
    color: $g-brand-color-1;
    font-weight: bold;
    margin-top: 1px;
  }

  &__overflow-item-remove {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__phantom-text {
    position: relative;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;

    span {
      position: absolute;
      height: 0;
      top: 0;
      left: 0;
    }
  }

  &__suggestions-anchor {
    position: relative;
  }

  &__suggestions {
    background-color: $g-white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 10px 0;
    margin-top: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__suggestions-list {
    overflow-y: auto;
    max-height: 135px;
    padding: 0;
    margin: 0;
  }

  &__suggestions-item {
    cursor: pointer;
    color: #6e7191;
    padding: 5px 32px;
    transition: $common-transition;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    strong {
      color: #14142b;
    }

    &:hover {
      background-color: #f2f4f5;
    }
  }

  &__bullet {
    margin: 0 13px;

    &:before {
      content: '\2022';
    }
  }

  &__desc {
    font-size: 13px;
    opacity: 0.8;
    line-height: 1;
  }

  &__suggestions-empty {
    color: #6e7191;
    padding: 5px 24px;
  }

  &__button {
    height: 38px;
    border-radius: 50px;
    background-color: $g-brand-color-1;
    color: $g-white;
    min-width: 85px;
    text-align: center;
    border: 2px solid $g-brand-color-1;
    margin: 0 0 0 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: normal;
    width: 260px;
    height: 60px;

    &--light {
      color: $g-brand-color-1;
      background-color: $g-white;
      font-weight: bold;

      span {
        margin-left: 16px;
      }
    }
  }

  &__form-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    max-width: 790px;
    width: 100%;
  }

  &__heading {
    text-align: center;
    padding: 0;
  }

  &__sub-heading {
    max-width: 650px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;

    svg {
      margin: 0 8px;
    }
  }

  &__search-icon {
    height: 18px;
  }

  &__input-field-wrapper {
    padding: 12px 16px;
    background-color: $g-white;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 12px;
  }

  &__result {
    height: calc(100vh - 80px);
    overflow-y: auto;
  }

  &__result-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px;

    h3 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  }

  &__property-type {
    padding: 8px 14px;
    border: 1px solid $g-brand-color-1;
    color: $g-brand-color-1;
    font-weight: bold;
    border-radius: 8px;
    font-size: 0.38em;
    margin-left: 20px;
    margin-top: -5px;
  }

  &__block {
    padding: 35px;
    background: $g-white;
    margin-top: 40px;
    border-radius: 14px;
    border: 1px solid #d9dbe9aa;

    &:first-child {
      margin-top: 0;
    }
  }

  &__back-button,
  &__copy-button {
    height: 38px;
    border-radius: 22px;
    padding: 0 20px;
    text-align: center;
    border: 1px solid $g-dark-color-3;
    margin: 0 0 0 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: normal;

    color: $g-brand-color-1;
    background-color: transparent;
    font-weight: bold;

    svg {
      margin-right: 8px;
    }
  }
}
