.l-search-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  border-radius: 10px;
  background-color: #14142b22;
  // background-color: transparent;

  &__spin-box {
    background-color: #14142b88;
    padding: 10px;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    // .ant-spin-dot-item {
    //   background-color: $g-white;
    // }
  }

  &__message {
    font-weight: 500;
    margin-top: 2px;
    opacity: 0.85;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.45);
    margin-left: -11px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.l-report {
  .l-search-loader {
    width: 100% !important;

    &--report-transparent {
      background-color: transparent;
    }
  }
}
