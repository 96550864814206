@import './plan_modal/plan_modal.style';
@import './add_user_modal/add_user_modal.style';

.bill-plan-info {
  padding: 40px;

  &__sections {
    display: flex;
    justify-content: space-between;
  }
  &__info-section {
    width: 55%;
    border: 1px solid $g-dark-color-3;
    border-radius: 5px;
  }

  &__btn-view-plan {
    border-radius: 22px;
    width: 110px;
    height: 42px;
    border-color: $g-dark-color-3;
    font-weight: 600;
    font-size: 13px;
    color: $g-brand-color-1;
  }

  &__plan-table {
    border-collapse: collapse;
    width: 100%;
    padding: 8px;

    th,
    td {
      padding: 32px;
      text-align: left;
      font-weight: normal;
      cursor: pointer;
      line-height: 22px;
    }

    tr {
      position: relative;
      &:not(:last-child) {
        // border-bottom: 1px solid #ddd;
      }
      &:before {
        content: '';
        border-bottom: 1px solid $g-dark-color-3;
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: -1px;
      }
    }
  }
  &__plan-table-col-1 {
    width: calc(200% / 4);
  }
  &__user-details {
    display: flex;
    align-items: center;
  }
  &__user-details-title {
    font-weight: $g-font-weight-heading-base;
    font-size: 15px;
    color: $g-black;
  }
  &__user-details-desc {
    letter-spacing: 0.75px;
    color: $g-dark-color-2;
  }
  &__plan-add-user-wrapper {
    display: flex;
    align-items: center;
    color: $g-brand-color-5;
    flex-direction: row;
    font-size: 15px;
    &--active {
      color: $g-brand-color-1;
    }
  }
  &__plan-add-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  &__plan-add-user-req-statement {
    color: $g-dark-color-2;
    font-size: 15px;
  }

  &__additional-user-options {
    display: flex;
    align-items: center;
    font-size: 21px;
    &--edit {
      margin-right: 50px;
      svg {
        height: 21px;
        width: 21px;
      }
    }
    &--delete {
      svg {
        height: 22px;
        width: 22px;
        path {
          stroke: $g-brand-color-1;
        }
      }
    }
  }
}

.ant-checkbox-inner {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.ant-checkbox-inner::after {
  top: 48%;
  left: 24.5%;
  display: table;
  width: 7.714286px;
  height: 17.142857px;
}
