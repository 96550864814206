%profile_placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-top: 20px;
  border-radius: 5px;
}

.c-profile-popover {
  width: 300px;
  @extend %profile_placeholder;

  &__usr-avatar {
    margin-bottom: 10px;
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__handle {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  &__followers {
    font-size: 13px;
    font-weight: 400;
    color: $g-brand-color-5;
    margin-bottom: 20px;
  }
  &__edit-btn {
    font-size: 13px;
    border-radius: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-brand-color-1;
    margin-bottom: 20px;
    @include bpm-sm {
      margin-bottom: 30px;
    }
    svg {
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  }
  &__list {
    border-top: 1px solid #eee;
    width: 100%;
    padding: 0px;
  }
  &__list-item {
    height: 54px;
    border-bottom: 1px solid #eee;
    display: flex;
    cursor: pointer;
    // justify-content: center;
    align-items: center;
    padding: 10px 24px;
    font-weight: 600;
    font-size: 13px;
    transition: $g-trans-base;
    @include bpm-sm {
      height: 65px;
    }
    &:hover {
      background: $g-light-color-1;
    }
  }
}

.c-profile-mobile-view {
  @extend %profile_placeholder;
}

.c-privacy-modal {
  .ant-modal-body {
    padding: 10px 20px;
  }
}

.c-profile-form {
  &__wrapper {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      width: 100%;
    }
  }
  &__avatar {
    margin-bottom: 20px;
  }
  &__avatar-wrapper {
    position: relative;
    &:hover .c-profile-form__avatar-overlay {
      opacity: 1;
    }
  }
  &__avatar-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #14142b99;
    transition: $g-trans-base;
    cursor: pointer;
  }

  &__save-btn {
    width: 100%;
    border-radius: 30px;
    height: 56px;
    font-size: 15px;
    margin-top: 10px;
  }

  &__error {
    color: red;
  }
}

.l-profile-edit-form {
  padding: 10px;

  .c-input-wrapper {
    width: 100%;
    margin-bottom: 0;
    margin-top: 12px;
  }
}
