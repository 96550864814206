.c-layout-header {
  &__fav-popover {
    cursor: pointer;
    margin-right: 20px;
  }
}
.c-favorite-tabs {
  .ant-tabs {
    &-nav-list {
      width: 100%;
    }
    &-nav {
      display: flex;
      width: 100%;
      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        // width: 100%;
        padding: 20px 0px;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.c-favorite {
  &__content {
    max-height: 400px;
    overflow-y: auto;
  }

  &__empty {
    background-color: $g-gray-color-6;
    margin: 0 16px 16px;
    padding: 24px;
    border-radius: 12px;

    strong {
      font-size: 16px;
      color: #23005c;
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }

    span {
      color: #4e4b66;
      display: block;
      text-align: center;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 16px;
    }

    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    &:hover {
      background: $g-light-color-1;
    }
  }
  &__list-item-lfet-side {
    display: flex;
    align-items: center;
  }
  &__list-main-title {
    font-size: 15px;
    font-weight: 600;
    color: $g-brand-color-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
  }
  &__list-pre-title {
    font-size: 12px;
    font-weight: bold;
  }
  &__list-sub-title {
    font-size: 12px;
    font-weight: 400;
    color: $g-gray-color-1;
  }
  &__list-icon {
    margin-right: 15px;
  }
  &__list-item-del-btn {
    width: 42px;
    background: $g-light-color-1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 42px;
    cursor: pointer;
    transition: $g-trans-base;
    &:hover {
      background: $g-white;
      path {
        stroke: $g-error !important;
      }
    }
  }
  &__list-item-right-side {
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: $g-trans-base;
  }
  &__list-item-del-op-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;

    &--hidden {
      visibility: hidden;
      right: 0px;
      transition: none;
      opacity: 0;
    }
    &--visible {
      visibility: visible;
      transition: all 0.9s ease-in-out;
      opacity: 1;
      right: 10px;
    }
  }
  &__list-item-del-btn-confirm {
    border-radius: 22px;
  }
}
