.l-save-search-modal {
  &__content {
    padding: 24px;

    .ant-checkbox-inner {
      height: 16px;
      width: 16px;
      border-radius: 2px;
    }
  }

  &__error {
    color: #e61b00;
    width: 100%;
    font-size: 13px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__close {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    position: absolute;
    top: 62%;
    right: 44%;
    margin-right: -13px;
    margin-top: -13px;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__footer {
    padding: 16px;
    border-top: 1px solid $g-gray-color-6;
  }

  &__button-group {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .h-muted {
    color: $g-dark-color-5;
    text-transform: uppercase;
    font-weight: normal;
  }

  &__button {
    background: $g-brand-color-1;
    min-width: 155px;
    color: $g-white;

    height: 42px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 100%;

    &--subtle {
      background-color: $g-gray-color-6;
      color: #4e4b66;
    }
  }

  &--mobile {
    position: relative;
  }

  &--mobile & {
    &__content {
      max-height: none;
      padding-bottom: 90px;
    }

    &__footer {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: $g-white;
    }
  }

  .ant-radio-button-wrapper {
    width: 100px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    border-color: transparent;
    height: 40px;
    font-size: 14px;
    box-shadow: none;
  }

  .ant-radio-group {
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}
