.l-onboard-success {
  &__content-wrapper {
    padding: 50px;
  }
  &__header-icon {
    margin-bottom: 20px;
    svg {
      height: 70px;
      width: 70px;
      stroke: $g-brand-color-1;
      path {
        stroke: $g-brand-color-1;
      }
    }
  }
  &__header-title {
    font-weight: $g-font-weight-bold;
    font-size: 36px;
    font-family: $g-font-heading;
    color: $g-font-heading-color;
    margin-bottom: 16px;
  }
  &__desc {
    font-size: 15px;
    color: $g-dark-color-2;
    width: 454px;
    letter-spacing: 0.75px;
    margin-bottom: 20px;
  }
  &__invitee {
    color: $g-black;
    letter-spacing: 0.75px;
    margin-bottom: 20px;
  }
  &__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__btn {
    width: 92px;
    height: 56px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: $g-font-weight-bold;
  }
}
