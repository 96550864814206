@import './metrics_form/metrics_form.style';

.l-map {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  flex: 1 1 100%;
  z-index: 2;

  &--tablet-mobile {
    margin-top: 10px;
    height: calc(100vh - 230px);
  }

  &--mobile {
    border-radius: 0;
    width: 100%;
    margin-top: 0;
    // height: calc(100vh - 173px);
  }

  &--mobile & {
    &__heat-map-button-group {
      top: 10px;
      right: 10px;
    }
  }

  &__popup,
  &__detailed-popup {
    font-size: 14px;
    font-family: $g-font-base;
    padding: 5px 10px 0;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;

    > span {
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }

    > span + span {
      font-weight: normal;
      margin-top: 8px;
      font-size: 14px;
    }
  }

  &__detailed-popup {
    min-width: 300px;
    position: relative;

    span:first-child {
      margin-bottom: 10px;
    }

    > span + span {
      margin-top: 2px;
    }

    strong {
      margin-left: 8px;
    }

    a {
      margin-top: 10px;
    }

    .img-cont {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 150px;
      height: calc(100% + 5px);
      width: 100%;
      border-radius: 8px;

      > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 8px;
      }
    }

    &--has-image {
      padding-left: 170px;
    }
  }

  &__marker {
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      top: -50%;
      left: -50%;
      margin-top: -17px;
      margin-left: -16px;
      height: 34px;
      width: 32px;
    }
  }

  &__marker-favorite {
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      top: -50%;
      left: -50%;
      margin-top: -19px;
      margin-left: -18px;
      height: 38px;
      width: 35px;
    }
  }

  &__point {
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      top: -50%;
      left: -50%;
      margin-top: -15px;
      margin-left: -15px;
      height: 30px;
      width: 30px;
    }
  }

  &__point-favorite {
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      top: -50%;
      left: -50%;
      margin-top: -15px;
      margin-left: -15px;
      height: 34px;
      width: 34px;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  &__button {
    background-color: $g-offwhite;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);

    &:last-child {
      margin-top: 0;
    }
  }

  &__heat-map-button-group {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    height: 42px;

    > div {
      > button {
        margin-right: 0;
      }

      > button:last-child {
        margin-top: 5px;

        svg {
          width: 18px;
        }
      }
    }
  }

  &__heat-map-button {
    background-color: $g-offwhite;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);

    &:last-child {
      margin-right: 0;
    }

    &--large {
      width: auto;
      border-radius: 22px;
      padding: 0 22px;
      color: #23005c;
      font-size: 13px;
      font-weight: bold;

      svg {
        margin-right: 8px;
      }
    }
  }

  &__suburb-name {
    background-color: $g-white;
    padding: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 3;

    strong {
      padding: 0 0 0 10px;
    }

    button {
      background: $g-brand-color-1;
      min-width: 155px;
      color: $g-white;

      height: 42px;
      border-radius: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;

      width: 100%;
      margin-top: 10px;
    }
  }

  .mapboxgl-popup-content {
    border-radius: 10px;
  }

  &__search-button {
    background-color: $g-offwhite;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    width: auto;
    border-radius: 22px;
    padding: 0 22px;
    color: #23005c;
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
  }

  &__map-search-text {
    background-color: $g-offwhite;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    width: auto;
    border-radius: 5px;
    padding: 0 22px;
    color: #23005c;
    font-size: 15px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    span + span {
      margin-left: 10px;
    }

    @include bp-xl {
      bottom: auto;
      top: 10px;
    }
  }
}
