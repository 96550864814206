$hover-bg: darken($g-brand-color-11, 5%);

%chat-wrapper-style {
  display: flex;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
  transition: $g-trans-base;
  &:hover {
    background: $hover-bg;
  }

  padding: 8px 16px;

  @include bp-sm {
    padding: 8px 32px;
  }
}

%sender-name {
  color: $g-dark-color-1;
  font-size: 13px;
  font-weight: 600;
  margin: 0 8px;

  @include bp-sm {
    margin: 0;
  }
}

%time {
  font-weight: 400;
  font-size: 13px;
  color: $g-dark-color-2;
}

.chat-message {
  &__wrapper {
    @extend %chat-wrapper-style;
    &--sender {
      flex-direction: row-reverse;
    }
    &--receiver {
      flex-direction: row;
    }
    &:hover {
      background: $hover-bg;

      .chat-message__action-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
    &--highlight {
      background: $hover-bg;
    }
  }

  &__action-wrapper {
    display: none;
    align-items: center;
    position: absolute;
    top: 30%;
    z-index: 1;
    transition: $g-trans-base;
    visibility: hidden;
    opacity: 0;
    &--sender {
      left: -50px;
    }
    &--receiver {
      right: -50px;
    }

    @include bp-sm {
      display: flex;
    }
  }
  &__reply-btn {
    background: transparent;
    border-color: $g-tgray-color-7;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
  &__message {
    padding: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    position: relative;
    width: fit-content;
    min-width: 100px;
    // @extend %text-ellipsis-placeholder;
    max-width: 500px;
    &--sender {
      color: $g-tgray-color-7;
      border-radius: 28px 0px 28px 28px;
      background-color: $g-dark-color-1;
      margin-left: auto;
      a {
        color: $g-tgray-color-7 !important;
      }
    }
    &--receiver {
      border-radius: 0px 28px 28px 28px;
      background: $g-tgray-color-7;
      color: $g-dark-color-2;
      margin-right: auto;
      a {
        color: $g-dark-color-2 !important;
      }

      .chat-mention {
        color: $g-brand-color-1 !important;
        text-shadow: none !important;
      }
    }
  }
  &__avatar-wrapper {
    width: 40px;
    &--sender {
      margin-left: 10px;
    }
    &--receiver {
      margin-right: 10px;
    }
  }
  &__sender-details {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    &--sender {
      flex-direction: row-reverse;
    }
  }
  &__sender-name {
    @extend %sender-name;

    &--sender {
      text-align: end;
    }
  }
  &__time {
    @extend %time;

    &--sender {
      text-align: end;

      @include bp-sm {
        margin-right: 10px;
      }
    }
    &--receiver {
      @include bp-sm {
        margin-left: 10px;
      }
    }
  }
  &__msg-file {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }

  &__msg-file-icon {
    margin: 0px 10px;
    span {
      font-size: 60px !important;
    }
  }
  &__msg-file-name {
    max-width: 200px;
    @extend %text-ellipsis-placeholder;
  }

  &__view-replies {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    margin-top: 5px;
    transition: background 0.3s ease-in-out;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $g-tgray-color-7;
      border-radius: 6px;
      background: rgba(252, 252, 252, 0.4);
      .chat-message__reply-to-th {
        display: block;
      }
      .chat-message__last-reply {
        display: none;
      }
    }
  }
  &__replies-info-wrapper {
    display: flex;
    align-items: center;
    font-size: 13px;
    min-width: 200px;
  }
  &__replies-count {
    color: $g-brand-color-1;
    font-weight: 600;
    letter-spacing: 0.25px;
    margin-right: 10px;
  }
  &__reply-to-th {
    display: none;
    color: $g-dark-color-2;
    transition: $g-trans-base;
  }
  &__last-reply {
    color: $g-dark-color-2;
    transition: $g-trans-base;
  }
}

.chat-thread-message {
  @extend %chat-wrapper-style;
  flex-direction: column;
  padding: 8px 20px;

  &__info-wrapper {
    display: flex;
    align-items: center;
    line-height: 1.3;
    margin-bottom: 5px;
    &--sender {
      flex-direction: row-reverse;
    }
    &--receiver {
      flex-direction: row;
    }
  }
  &__sender-name {
    @extend %sender-name;
    margin-right: 5px;
  }
  &__time {
    @extend %time;
  }
  &__avatar-wrapper {
    width: 25px;
    @include bp-sm {
      margin-right: 5px;
    }
  }
  &__message {
    font-weight: 400;
    font-size: 13px;
    position: relative;

    .chat-mention {
      color: $g-brand-color-1 !important;
      text-shadow: none !important;
    }
  }
}

.l-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  min-height: 180px;
  margin: 0 auto;
  padding: 20px 0;

  @include bp-sm {
    padding: 0;
  }

  &__usr-avatar {
    margin-bottom: 10px;
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__handle {
    font-size: 13px;
    margin-bottom: 4px;
    color: $g-brand-color-5;
  }
  &__occ {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  &__followers {
    font-size: 13px;
    font-weight: 400;
    color: $g-brand-color-5;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__bio {
    margin: 10px 0;
    text-align: center;
  }
  &__btn {
    font-size: 13px;
    border-radius: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-brand-color-1;
    margin: 20px 0 0;
    line-height: 1;
  }
}
