.l-plan-add-user-modal {
  .ant-modal-body {
    padding: 32px;
    border-radius: 20px;
  }

  &__header {
    font-size: 18px;
    color: $g-gray-color-2;
  }

  &__input-wrapper {
    margin-bottom: 30px;
  }

  &__input-label {
    font-size: 15;
    font-weight: 400;
    color: $g-dark-color-2;
    margin-bottom: 10px;
  }

  &__input {
    width: 100%;
    height: 50px;
    background: #fcfcfc;
    /* Alias/Grayscale/Header */

    // border: 2px solid #14142b;
    border-radius: 12px;
  }

  &__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    font-size: 15px;
    font-weight: 600;
    padding: 16px 24px;
    border-radius: 56px;
    width: 106px;
    height: 56px;
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 12px 12px 0;
  }

  .ant-input-number-input-wrap {
    height: 48px;
    border-radius: 12px;
  }

  .ant-input-number-input {
    height: 48px;
    border-radius: 12px;
  }
}
