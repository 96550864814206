@import './onboarding/onboarding.style';
@import './components/organization_settings/organization_settings.style';
@import './components/invite_team/invite_team.style';
@import './settings/settings.style';
@import './members/members.style';
@import './members/edit_member/edit_member.style';
@import './settings/update_card_modal/update_card_modal.style';
@import './update_plan/update_plan.style';
@import './update_seats/update_seats.style';

.l-account {
  padding: 60px 80px;
  width: 100%;
  background: $g-white;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
  }
  &__con-img-wrapper {
    width: 100px;
    height: 85px;
    border-radius: 20px;
  }
  &__header-con {
    margin-right: 15px;
  }
  &__company-name {
    font-size: 15px;
    letter-spacing: 0.75px;
    color: $g-dark-color-2;
  }
  &__company-label {
    font-size: 13px;
    letter-spacing: 0.25px;
    color: $g-gray-color-1;
  }
  &__subs-label {
    margin-left: 22px;
    background: $g-brand-color-10;
    padding: 2px 12px;
    border-radius: 8px;
    color: $g-gray-color-2;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.25px;
  }

  &__acc-settings {
    color: $g-dark-color-2;
    font-weight: $g-font-weight-heading-base;
    font-size: 24px;
    letter-spacing: 0.75px;
    font-family: $g-font-heading;
  }
  &__content-wrapper {
    padding: 20px 0px;

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $g-brand-color-1;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.45px;
    }

    .ant-tabs-tab-btn {
      color: $g-gray-color-1;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.45px;
    }
    .ant-tabs-ink-bar {
      background-color: $g-brand-color-1;
    }
  }
}
