@import '../foundation/components/full_page_loader/full_page_loader.style';
@import '../foundation/components/float_label/float_label.style';
@import '../foundation/components/static_content/template/template.style';
@import '../features/user/user.style';
@import '../features/search/search.style';
@import '../features/layout/layout.style';
@import '../foundation/components/input/input.style';
@import '../foundation/components/textarea/textarea.style';
@import '../foundation/components/chip_input/chip_input.style';
@import '../foundation/components/error_field/error_field.style';
@import '../features/chat/chat.style';
@import '../features/admin/channel_request/channel_request.style';
@import '../features/billing/billing.style';
@import '../features/account/account.style';
@import '../features/report/report.style';
@import '../features/ai_report/ai_report.style';

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: $g-dark-color-3;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: $g-brand-color-5;
}

.u-content-modal {
  .ant-modal-title {
    font-family: $g-font-heading;
    line-height: 1.5;
    font-size: 18px;

    @include bp-md {
      font-size: 24px;
    }
  }

  .ant-modal-header {
    border-bottom: $g-dark-color-3 1px solid;
    padding: 16px 20px;

    @include bp-md {
      padding: 24px 32px;
    }
  }
}

.h-iframe {
  width: 100%;
  display: block;
  border: $g-dark-color-3 1px solid;
  border-radius: 16px;
  background-color: #fff;
}

.h-iframe-wrapper {
  position: relative;
}

.h-iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;

  &--read-only {
    cursor: default;
  }
}
