%placeholder-save-tag {
  background-color: #dbfaca;
  color: #08780c;
  padding: 5px 12px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
}

.l-billing-summary {
  position: relative;
  width: 38%;
  border: 1px solid $g-dark-color-3;
  border-radius: 5px;
  padding: 32px;
  .ant-divider {
    border-top: 1px solid $g-dark-color-3;
  }
  &__plan-name {
    font-size: 16px;
    font-weight: $g-font-weight-heading-base;
    color: $g-black;
    margin-bottom: 10px;
  }
  &__interval-wrapper {
    display: flex;
  }
  &__save-tag {
    @extend %placeholder-save-tag;
  }
  &__item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $g-gray-color-2;
    margin-bottom: 25px;
  }
  &__item-feature {
    font-size: 13px;
    color: $g-dark-color-2;
  }
  &__item-price {
    font-weight: 600;
    margin-bottom: 5px;
    text-align: end;
  }
  &__item-save-tag {
    @extend %placeholder-save-tag;
  }

  &__promo-field-wrapper {
    display: flex;
    width: 80%;
    align-items: center;

    .ant-input-status-error {
      border-color: #cb0049;
      box-shadow: 0 0 0 2px rgb(203 0 73 / 31%);
    }
  }
  &__promo-field {
    background-color: $g-light-color-1;
    @include custom-placeholder($g-gray-color-1, 15, $font);
    border-radius: 12px;
    box-shadow: 0 0 0 transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    cursor: text;
  }
  &__promo-btn {
    color: $g-brand-color-1;
    font-weight: 600;
    font-size: 13px;
    opacity: 0.5;
    margin-left: 30px;
    /* Alias/Grayscale/Line */

    border: 1px solid #d9dbe9;
    border-radius: 22px;
  }

  &__promo-del-icon {
    margin-left: 5px;
    color: $g-error;
    cursor: pointer;
  }
  &__submit-btn {
    border-radius: 22px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: #fcfcfc;
    width: 100%;
    height: 42px;
  }
  &__total-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $g-gray-color-2;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 25px;
  }
}
