.l-toggler {
  width: 100%;
  padding: 0 32px;

  .ant-tabs-tab {
    padding: 12px 40px;
    font-size: 14px;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $g-brand-color-1;
  }

  .ant-tabs-ink-bar {
    background-color: $g-brand-color-1;
  }
}

.l-search-field--compact {
  .l-toggler {
    padding: 0 6px;

    .ant-tabs-nav {
      margin: 0 0 8px 0;
    }
  }
}
