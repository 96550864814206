.l-settings {
  position: relative;
  padding: 10px;

  &__list {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__list-label,
  &__list-action {
    margin-bottom: 18px;
  }

  &__icon {
    margin-right: 10px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list-label {
    display: flex;
    align-items: center;
    flex: 1 1;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    height: 38px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 0 16px;

    border: $g-dark-color-3 1px solid;
    font-weight: bold;
    color: $g-brand-color-1;
    background-color: #fff;
    white-space: nowrap;
  }

  &__delete-button {
    height: 46px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0 16px;
    font-weight: bold;
    border: $g-dark-color-3 1px solid;
    color: #cb0049;
    background-color: #fff;
    white-space: nowrap;
    width: 100%;

    > svg {
      margin-left: 10px;
    }
  }
}
