.c-layout-header {
  background: $g-dark-color-1;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 40px;
  font-family: $g-font-base;
  @include bp-sm {
    background: $g-white !important;
  }
  @include bpm-sm {
    padding: 0px 20px;
    justify-content: space-between;
  }
  .ant-btn-round.ant-btn-lg {
    font-size: 15px;
    height: 50px;
    width: 135px;
    font-weight: 600;
    padding: 16px, 24px, 16px, 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  &__right-items {
    display: flex;
    align-items: center;
    // cursor: pointer;
  }

  &__invite-btn,
  &__feedback-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $g-brand-color-1;
    min-width: 165px;
    font-weight: normal;

    @include bpm-sm {
      background: $g-brand-color-3;
      height: 40px;
      width: 40px;
    }

    &:hover {
      @include bpm-sm {
        background: $g-brand-color-3;
      }
    }

    svg {
      margin-left: 8px;
      @include bpm-sm {
        margin-left: 0;
        height: 22px;
        width: 22px;
      }
    }
  }
  &__user-popover {
    cursor: pointer;
    margin-right: 20px;
  }
  &__notifications-trigger {
    margin-right: 30px;
    height: 50px;
    cursor: pointer;
    .ant-badge-dot {
      top: 2px;
      right: 5px;
      height: 10px;
      width: 10px;
    }
    @include bpm-sm {
      margin-right: 20px;
    }
  }
  &__favourites-trigger {
    margin-right: 30px;
    height: 50px;
    cursor: pointer;
    @include bpm-sm {
      margin-right: 20px;
    }
  }
  &__close-sider-btn {
    color: $g-dark-color-3;
    font-size: 18px;
    cursor: pointer;
  }
}
