.l-invite-members {
  width: 100%;
  position: relative;
  padding: 24px;

  &--success {
    overflow: hidden;
    border-radius: 20px;
    padding: 0;

    > svg {
      width: 100%;
    }
  }

  &__finish-up {
    padding: 24px;

    h5 {
      margin-bottom: 24px;
      padding: 0;
    }
  }

  &__summary {
    margin: 0 0 8px;
    padding: 0 0 0 12px;
    list-style: none;
  }

  &__summary-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-right: 16px;
    background: $g-light-color-1;
    border-radius: 50%;

    .anticon {
      width: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__summary-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  &__summary-item-flex {
    display: flex;
    flex-direction: column;
  }

  &__summary-item-email {
    margin-bottom: 1px;
  }

  &__summary-item-status {
    font-size: 12px;
  }

  &__summary-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .s-manage {
      margin-left: 12px;
      color: $g-brand-color-1;
      font-size: 14px;
    }

    .l-invite-members__button {
      min-width: 100px;
      width: 100px;
      flex: 0 0 100px;
      margin: 0;
    }
  }

  .fetch-spinner {
    position: absolute;
    top: 44px;
    right: 40px;
    z-index: 10;
  }

  &__form {
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    display: flex;
    flex: 1 1;
    max-height: 138px;
    overflow-y: auto;
    border: 1px solid #4e4b66;
    min-height: 60px;
    border-radius: 12px;
    padding: 4px 46px 4px 8px;

    input {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0 8px;
      background: 0 0;
      border: none;
      outline: none;
      width: 100%;
      max-width: 100%;
      height: 40px;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 14px;

      &[disabled] {
        opacity: 1;
        color: $g-dark-color-1;
      }

      min-width: 350px;
    }
  }

  &__overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
  }

  &__overflow-item-suggestion {
    background-color: #f2f4f5;
    border-radius: 6px;
    padding: 5px 8px 5px 15px;
    margin: 5px 10px 5px 0;
    display: flex;
    align-items: center;
    min-height: 40px;
    font-weight: normal;

    &--email {
      background-color: $g-brand-color-1-lite;
    }
  }

  &__overflow-item-remove {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__phantom-text {
    position: relative;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;

    span {
      position: absolute;
      height: 0;
      top: 0;
      left: 0;
    }
  }

  &__suggestions-anchor {
    position: relative;
  }

  &__suggestions {
    background-color: $g-light-color-1;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 8px 0;
    margin-top: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.04);

    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    z-index: 2;
    box-shadow: $common-box-shadow;
    border: 1px solid $g-dark-color-3;
  }

  &__suggestions-list {
    overflow-y: auto;
    max-height: 135px;
    padding: 0;
    margin: 0;
  }

  &__suggestions-item {
    cursor: pointer;
    color: #14142b;
    padding: 5px 20px;
    transition: $common-transition;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    strong {
      color: #14142b;
    }

    &:hover {
      background-color: #fff;
    }
  }

  &__suggestions-empty {
    color: #14142b;
    padding: 5px 24px;
  }

  &__button {
    background: $g-brand-color-1;
    min-width: 155px;
    color: $g-white;

    height: 56px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex: 1 1 200px;
    width: 218px;
    margin-top: 20px;
    margin-left: auto;
    padding: 0 20px;

    &--subtle {
      background-color: $g-gray-color-6;
      color: #4e4b66 !important;
    }
  }

  &__details-toggle {
    position: absolute;
    top: 3px;
    right: 14px;
    z-index: 5;
    cursor: pointer;
    font-weight: normal;

    padding: 10px;
    display: flex;
    // height: 20px;
    // width: 20px;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      font-weight: bold;
    }
  }

  .s-error {
    color: red;
  }
}
