@import './order_summary/order_summary.style';

.l-update-plan {
  position: relative;
  padding: 60px 100px;
  width: 100%;
  height: 100%;
  background: $g-white;

  &__back-btn {
    padding: 9px, 16px, 9px, 16px;
    height: 42px;
    width: 94px;
    border: 1px solid #d9dbe9;
    border-radius: 22px;
    font-weight: 600;
    font-size: 13px;
    color: $g-brand-color-1;
    transition: $g-trans-base;
    margin-bottom: 30px;
    svg {
      height: 9.33px !important;
      width: 11.33px !important;
      margin-right: 10px;
      path {
        fill: $g-brand-color-1;
      }
    }
  }
  &__heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: $g-font-heading;
    color: $g-gray-color-2;
  }

  &__sub-heading {
    // font-family: $g-font-heading;
    color: $g-gray-color-2;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__content-container {
    display: flex;
    justify-content: space-between;
  }

  &__plan-card {
    display: flex;
    padding: 20px 16px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    width: 532px;
    height: 95px;
    background: $g-white;
    cursor: pointer;
    margin-bottom: 15px;
    &--selected {
      border: 2px solid $g-brand-color-1;
    }
    &--payment {
      justify-content: space-between;
      align-items: center;
    }
  }

  &__card-info {
    font-size: 18px;
    color: $g-black;
    letter-spacing: 0.25px;
  }

  &__card-expiry {
    font-size: 18px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__plan-name {
    font-weight: 400;
    font-size: 15px;
    color: $g-dark-color-2;
    letter-spacing: 0.25px;
  }

  &__plan-details {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $g-gray-color-1;
  }

  &__plan-card-checkbox-wrapper {
    margin-right: 16px;
  }

  &__message {
    background: $g-light-color-1;
  }

  &__message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 532px;
  }

  &__message-icon {
    line-height: 0;
    justify-content: center;
    text-align: center;
    display: flex;
  }
  &__message {
    padding: 16px;
    border-radius: 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.75px;
    color: $g-dark-color-1;
  }
}
