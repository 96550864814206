%chat-window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background: rgba(252, 252, 252, 0.6);
  width: 100%;
  border-top: 1px solid $g-light-color-1;
  transition: $g-trans-base;
  padding: 0 16px;

  @include bp-sm {
    padding: 0px 32px;
  }
}

%chat-window-msg-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  // height: calc(100% - 264px);
  height: calc(100vh - 246px);

  @include bp-sm {
    height: calc(100vh - 344px);
  }

  .chat-mention {
    color: #fff;
    font-weight: bold;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
    position: relative;
    font-size: 14px;
    text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
  }
}

%chat-window-msg-editor-container {
  max-height: 200px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  padding: 16px 16px;

  @include bp-sm {
    padding: 32px 32px;
  }

  .chat-mention {
    color: $g-brand-color-1;
    font-weight: bold;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
    position: relative;
  }
}

.l-chat-thread {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  background-color: $g-white;

  @include bp-sm {
    width: 300px;
    height: 100%;
    color: $g-gray-color-2;
    position: relative;
    top: auto;
    right: auto;
  }

  &__header {
    @extend %chat-window-header;
  }

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__header-text {
    font-size: 15px;
    font-weight: 600;
    border-right-width: 1px;
    border-right-style: solid;
    padding-right: 10px;
    border-right-color: $g-gray-color-1;
  }

  &__header-chat-name {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    width: 140px;
    @extend %text-ellipsis-placeholder;
    color: $g-gray-color-1;
  }

  &__header-close-btn {
    cursor: pointer;
    svg {
      path {
        stroke: $g-gray-color-1;
      }
    }
  }

  &__msg-container {
    @extend %chat-window-msg-container;
  }

  &__msg-editor-container {
    @extend %chat-window-msg-editor-container;
  }
}
