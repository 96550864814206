.l-heading {
  &__body {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: $g-dark-color-3 1px solid;
  }

  &--read-only & {
    &__body {
      padding: 15px 0;

      @include bp-sm {
        margin: 0 20px 0 0;
      }
    }

    &__name {
      font-weight: normal;
      color: inherit;
    }

    &__title {
      cursor: default;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
  }

  &__name {
    font-size: 16px;
    color: $g-brand-color-1;
    font-weight: bold;
    text-align: left;

    &--teal {
      color: #00add2;
    }

    svg {
      margin-left: 8px;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
}
