.l-property-details {
  position: relative;
  width: 100%;
  flex: 1 1 100%;
  overflow: hidden;

  &--default,
  &--compact,
  &--wide,
  &--hidden {
    flex: 1 0 100%;
    transition: max-width 0.3s ease 0.1s;
    margin-right: 10px;
  }

  &--default {
    max-width: calc(50vw - 200px);
  }

  &--compact {
    max-width: 432px;
  }

  &--wide {
    max-width: calc(66.6vw - 200px);
  }

  &--hidden {
    max-width: 0;
    overflow: hidden;
  }

  &--hidden & {
    &__body {
      width: 432px;
    }
  }

  &--compact & {
    &__body {
      min-width: 432px;
    }
  }

  .l-property {
    margin-top: 0;
    border-radius: 10px;

    &__tag-list {
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid $g-dark-color-3;

      li {
        border-bottom: 1px solid $g-dark-color-3;
        margin-top: 0;
        padding-bottom: 15px;
        padding-top: 15px;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom-color: transparent;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__estimate-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    max-height: calc(100vh - 192px);
    padding-right: 10px;

    &--short {
      max-height: calc(100vh - 252px);
    }
  }

  &__content {
    // width: 60%;
    // margin-right: 20px;
    width: 100%;
  }

  &__map {
    max-height: 348px;
    height: 348px;
    // width: 40%;'
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: $common-box-shadow;
  }
}
