//////////////////////////
// Typography Mixins    //
//////////////////////////

@mixin h1 {
  font-size: 45px;
}

@mixin h2 {
  font-size: 38px;

  @include bp-md {
    font-size: 40px;
  }
}

@mixin h3 {
  font-size: 28px;

  @include bp-md {
    font-size: 36px;
  }
}

@mixin h4 {
  font-size: 21px;

  @include bp-md {
    font-size: 21px;
  }
}

@mixin h5 {
  font-size: 20px;

  @include bp-md {
    font-size: 18px;
  }
}

@mixin h6 {
  font-size: 16px;

  @include bp-md {
    font-size: 16px;
  }
}

.c-heading {
  font-size: $g-font-size-heading;
  font-weight: $g-font-weight-heading-base;
  font-family: $g-font-heading;
}

.c-info {
  font-size: 14px;
  font-weight: 400;
  color: $form-field-label;
  margin-bottom: 10px;
}
