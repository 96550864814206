.l-lightbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  background-color: #262338fd;
  padding: 0 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  &__carousel-wrapper {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__carousel-prev {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: -51px;
    z-index: 1;
  }

  &__carousel-next {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: -51px;
    z-index: 1;
  }

  &__carousel-slide {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp-xxl {
      height: 750px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--empty {
      background-color: $g-gray-color-1;
      height: 348px;

      svg {
        height: 50px;
        width: 50px;
        opacity: 0.6;

        path {
          fill: white;
        }
      }
    }
  }
}
