.l-direct-message-modal {
  &__ajax-select {
    height: 50px;

    .ant-select-selector {
      height: 50px !important;
    }
    input {
      height: 50px !important;
    }
    margin-bottom: 4px;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-selector {
      background-color: $g-light-color-1 !important;
      border: none !important;
      box-shadow: none !important;
      &:hover,
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
  &__ajax-select-label {
    color: $g-gray-color-1;
  }
}
