.l-filter-field {
  padding: 25px 0;
  border-top: 1px solid $g-dark-color-3;

  &:first-child {
    border-top: none;
  }

  &__label {
    // margin-bottom: 18px;
    margin-bottom: 9px;
    color: $g-dark-color-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 26px;
    font-weight: bold;
  }

  &__remove {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__input {
    display: flex;
    justify-content: space-between;
  }

  &__select {
    flex: 1 1 100%;
    max-width: 50%;

    &:first-child {
      margin-right: 10px;
    }

    > p {
      text-transform: uppercase;
      color: #aaa;
      font-size: 12px;
      padding: 0 5px;
    }

    .ant-select {
      width: 100%;
    }
  }

  &__slider {
    &--enabled {
      .ant-slider-handle {
        border: 2px solid #23005c !important;
      }

      .ant-slider-track {
        background-color: #23005c !important;

        &:hover {
          background-color: #23005c !important;
        }
      }
    }
  }
}

.l-filter-field-combo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0;

  &:first-child {
    margin-top: 24px;
  }

  &:last-child {
    margin-bottom: 24px;
  }

  &--mobile {
    flex-direction: column;
    width: 100%;

    .l-filter-field-combo {
      &__label {
        text-align: left;
        justify-content: flex-start;
        width: 100%;
        max-width: inherit;
        margin-bottom: 8px;
        margin-right: 0;
      }

      &__slider {
        flex: 2 1;
        margin: 0;
      }

      &__input {
        max-width: 100px;
      }
    }
  }

  &__field {
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--spacing {
      margin-bottom: 30px;
    }
  }

  &__label {
    margin-right: 18px;
    max-width: 100px;
    color: $g-dark-color-1;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-align: right;
    justify-content: flex-start;

    &--wide {
      max-width: 130px;
    }
  }

  &__control {
    display: flex;
    font-size: 12px;
    align-items: center;
    width: 100%;
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__mobile-input-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
