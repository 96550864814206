@import './header/header.style';
@import './sider/sider.style';
@import './mobile_content_sider/mobile_content_sider.style';

.c-app-layout {
  // height: 100% !important;
  min-height: 100vh;
  min-height: stretch;
}

.site-layout-background {
  background: $g-white;
}

.c-layout-menu {
  background: $g-dark-color-1 !important;
  .ant-menu-item {
    transition: $g-trans-base;
  }
  .ant-menu-item-selected {
    background: transparent !important;

    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      transform: scaleY(1);
      opacity: 1;
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-left: 3px solid $g-white;
    }
  }
}

.c-layout-logo {
  display: flex;
  align-items: center;
  font-family: $g-font-heading;
  font-weight: 700;
  font-size: 24px;
  margin: 10px;
  margin-bottom: 40px;
  margin-top: 10px;
  color: $g-white;
  padding: 0px 10px;
  overflow-x: hidden;

  svg {
    height: 45px;
    margin-right: 5px;
  }
}

.c-app-content {
  transition: $g-trans-base;
}

.l-layout {
  display: flex;
  overflow: hidden;
  position: relative;

  @include bp-sm {
    height: calc(100% - 80px);
    max-height: calc(100% - 80px);
  }
}
