.c-notification {
  &__heading {
    height: 62px;
    font-size: 14px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__content {
    max-height: 400px;
    overflow-y: auto;
    padding-top: 16px;
  }

  &__empty {
    background-color: $g-gray-color-6;
    margin: 0 16px 16px;
    padding: 24px;
    border-radius: 12px;

    strong {
      font-size: 16px;
      color: #23005c;
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }

    span {
      color: #4e4b66;
      display: block;
      text-align: center;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    padding: 10px;
    position: relative;

    &:hover {
      background: $g-light-color-1;
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  &__list-item-left-side {
    display: flex;
    align-items: center;
  }

  &__list-main-title {
    font-size: 15px;
    font-weight: 600;
    color: $g-brand-color-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
  }

  &__list-sub-title {
    font-size: 12px;
    font-weight: 400;
    color: $g-gray-color-1;
  }

  &__list-icon {
    margin-right: 15px;
    margin-left: 5px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      height: 10px;
      width: 10px;
      background-color: lighten($g-brand-color-1, 5%);
      border-radius: 50%;
    }
  }
}
